import { breadcrumbsIntegration, browserTracingIntegration, captureException, captureMessage, init as SentryInit } from '@sentry/browser'

import { NgModule, ErrorHandler, Injectable, ChangeDetectorRef, LOCALE_ID } from '@angular/core'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { registerLocaleData } from '@angular/common'

import { routing } from './app.routing'

import { environment } from '../environments/environment'

// Fileuploads
import { NgxFileDropModule } from 'ngx-file-drop'

// Firebase
import { FirebaseLemonator, FirebaseLemonaid } from './_angular/service/firebase-lemonator.service'
import { CdkTableModule } from '@angular/cdk/table'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { ClipboardModule } from '@angular/cdk/clipboard'

// Material
const globalRippleConfig: RippleGlobalOptions = { disabled: true }

import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions, MatNativeDateModule, DateAdapter } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSnackBarModule, MatSnackBar } from '@angular/material/snack-bar'
import { MatSortHeaderIntl, SortDirection, MatSortModule } from '@angular/material/sort'
import { MatStepperModule, MatStepperIntl } from '@angular/material/stepper'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatTreeModule } from '@angular/material/tree'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'

// Pipes
import { CurrencyPipe, BigCurrencyPipe, CurrencyWithLocalePipe, BigCurrencyWithLocalePipe } from './_jaettu-angular/_pipes/lemon-currency.pipe'
import { LemonKaannaPipe, LemonKaannaWithLocalePipe } from './_jaettu-angular/_pipes/lemon-translate.pipe'
import { LemonatorKaannaTilinpaatosPipe } from './_angular/_pipes/lemonator-translate.pipe'
import {
  LemonDatePipe,
  LemonDateAndTimePipe,
  LemonDateWithLocalePipe,
  LemonLocalDatePipe,
  LemonNumberDateWithLocaleNarrowPipe,
  LemonNumberDatePipe,
  LemonNumberDateWithLocalePipe,
  LemonLocalDateWithLocalePipe,
  LemonTimestampPipe,
  LemonTimestampWithLocalePipe,
  LemonTimestampDateAndTimePipe,
  LemonTimestampDateAndTimeWithLocalePipe,
  LemonLocalToMonthAndYearPipe,
  LemonNumberMonthToMonthAndYearPipe
} from './_jaettu-angular/_pipes/lemon-date.pipe'
import { LemonMaaPipe, LemonMaaWithlocalePipe } from './_jaettu-angular/_pipes/lemon-maa.pipe'
import { DecimalPipe, DecimalWithLocalePipe, BigDecimalPipe, BigDecimalWithLocalePipe } from './_jaettu-angular/_pipes/lemon-decimal.pipe'
import { PrettyPrintJsonPipe } from './_jaettu-angular/_pipes/lemon-json.pipe'
import { LemonatorNumberDateToDatePipe } from './_angular/_pipes/lemonator-date.pipe'

// Directives
import { LemonNumberOnlyDirective } from './_jaettu-angular/_directives/number-only.directive'
import { TimeFormatDirective } from './_jaettu-angular/_directives/time-format.directive'
import { PlaceholderWithRequiredIndicatorDirective } from './_jaettu-angular/_directives/placeholder-with-required-indicator.directive'
import { CountToDirective } from './_jaettu-angular/_directives/count-to.directive'
import { MonthPickerDirective } from './_jaettu-angular/_directives/month-picker.directive'
import { NumberDatePickerDirective } from './_jaettu-angular/_directives/number-date-picker.directive'
import { ResizedDirective } from './_angular/_directives/resized-event.directive'
import { OnDisabledDirective } from './_angular/_directives/ondisabled.directive'
import { RightClickMatMenuDirective } from './_angular/_directives/mat-menu-right-click-trigger.directive'

// Components
import { LoadingComponent } from './_jaettu-angular/_components/loading.component'
import { BarcodeComponent } from './_jaettu-angular/_components/barcode.component'
import { QrcodeComponent } from './_jaettu-angular/_components/qrcode.component'
import { ToggleArrow } from './_angular/_components/toggle-arrow'
import { ConfettiRain } from './kirjanpito/effects/confetti.component'
import { BrowserClockCheckComponent } from './_jaettu-angular/_components/browser-clock-check.component'

// Login, Forgot password, Reset password, home
import { AppComponent } from './app.component'
import { AppMenuComponent } from './app-menu.component'
import { LoginComponent } from './login/login.component'
import { LoggedOutComponent } from './login/loggedout.component'


// Dialogit
import { VaihdaKieliDialog } from './vaihda-kieli.dialog'
import { ChangelogDialog } from './changelog.dialog'
import { ServiceWorkerCacheInfoDialog } from './yllapito/service-worker-cache/cache-info.dialog'
import { VirheDialog } from './_jaettu-angular/_components/virhe.dialog'
import { AreYouSureDialog } from './_jaettu-angular/_components/are-you-sure.dialog'
import { InfoDialog } from './_jaettu-angular/_components/info.dialog'
import { KirjanpitoValmisDialog } from './kirjanpito/dialogit/kirjanpito-valmis.dialog'
import { KirjanpitoLisaaTiliotteenSaldoDialog } from './kirjanpito/dialogit/kirjanpito-lisaa-tiliotteen-saldo.dialog'
import { TaukoMuistutusDialog } from './asiakkaat/listaus/tauko-muistutus.dialog'

// Services
import { DebugService } from './_angular/service/debug.service'
import { LemonHttpService } from './_angular/service/lemon-http.service'
import { VersionTarkistusPalvelu } from './_angular/service/version-tarkistus.palvelu'
import { KirjautunutKayttajaService } from './_angular/service/kirjautunut-kayttaja.service'
import { KirjanpitoKelloService } from './_angular/service/kirjanpito/kirjanpito-kello.service'
import { AlvLemonatorService } from './_angular/service/alv-lemonator.service'

import { CookieService } from './_jaettu-angular/service/cookie.service'
// import { CacheService } from './_jaettu-angular/service/cache.service'
import { WindowSizeService } from './_jaettu-angular/service/window.service'
import { DragAndDropService } from './_jaettu-angular/service/drag-and-drop.service'
import { TiedostojenLataamisService } from './_jaettu-angular/service/tiedostojen-lataamis.service'
import { LemonTranslationService } from './_jaettu-angular/service/lemon-translation.service'
import { VasenValikkoService } from './_jaettu-angular/service/vasen-valikko.service'
import { LadataanService } from './_jaettu-angular/service/ladataan.service'
import { TimestampService } from './_jaettu-angular/service/timestamp-service'
import { PdfService } from './_jaettu-angular/service/pdf.service'
import { MaaService } from './_jaettu-angular/service/maa.service'
import { FormValidationService } from './_jaettu-angular/service/form-validation.service'
import { AlvService } from './_jaettu-angular/service/alv.service'
import { FileSaverService } from './_jaettu-angular/service/file-saver'

import { TimestampProviderBase } from './_shared-core/service/timestamp-provider.interface'

import { YllapitoUriService } from './_jaettu-lemonator/service/yllapito-uri.service'
import { PyhapaivatService } from './_shared-core/service/pyhapaiva.service'
import { TyoajanseurantaService } from './_jaettu-lemonator/service/tyoajanseuranta.service'
import { HolviSharedUriService } from './_jaettu-lemonator/service/holvi-shared-uri.service'

// Shared
import { BicService } from './_shared-core/service/bic.service'
import { IbanService } from './_shared-core/service/iban.service'
import { ViitenumeroService } from './_shared-core/service/viitenumero.service'
import { CurrencyService } from './_shared-core/service/currency.service'
import { DateService } from './_shared-core/service/date.service'
import { StringService } from './_shared-core/service/string.service'
import { FirestoreIndeksoija } from './_jaettu/service/firestore.indeksoija'
import { KopioijaPalvelu } from './_jaettu/service/kopioija.service'
import { CodeCheckService } from './_shared-core/service/code-check.service'
import { TranslationService, CountryLocalizationService } from './_jaettu/service/translation.service'
import { KycUriService } from './_jaettu/service/kyc-uri.service'
import { VatNumberValidationService } from './_jaettu/service/vat-number-validation.service'
import { VahvistettuKorkoService } from './_jaettu/service/vahvistettu-korko.service'

import {
  BicServiceAngular,
  IbanServiceAngular,
  ViitenumeroServiceAngular,
  CurrencyServiceAngular,
  DateServiceAngular,
  FirestoreIndeksoijaAngular,
  KopioijaPalveluAngular,
  CodeCheckServiceAngular,
  StringServiceAngular,
  TranslationServiceAngular,
  LaskuIndeksoijaAngular,
  LaskuKopioijaAngular,
  LaskuKorkoServiceAngular,
  LaskuSharedServiceAngular,
  LaskuSpostiServiceAngular,
  LaskuUriServiceAngular,
  LaskuLahetystietojenLuojaAngular,
  ReskontraServiceAngular,
  TositeKopioijaAngular,
  TositeIndeksoijaAngular,
  TositeUriServiceAngular,
  SahkoisenLaskunValittajaServiceAngular,
  KycUriServiceAngular,
  VatNumberValidationServiceAngular,
  AjastettuTyoServiceAngular,
  BankConsentUriServiceAngular,
  TilinpaatosUriServiceAngular,
  ApixUriServiceAngular,
  AsiakasJaettuLemonaidServiceAngular
} from './_jaettu-angular/angularified-services'

import { Aikakone } from './_jaettu-angular/service/aikakone'
import { AjastettuTyoService } from './_jaettu/eraajot/ajastettu/ajastetut-tyot.service'

// Guards
import { AuthGuard } from './_angular/_guards/auth.guard'
import { AsiakasGuard } from './_angular/_guards/asiakas.guard'
import { YllapitoGuard } from './_angular/_guards/yllapito.guard'

// Resolvit
import { AsiakasComponentDataResolve, KayttajaComponentDataResolve, KirjanpitoComponentDataResolve } from './_angular/_resolvers/asiakas.resolve'
import { LaskuComponentDataResolve, LaskuKatseleComponentDataResolve, LaskuSelaaKatseleComponentDataResolve } from './_angular/_resolvers/lasku.resolve'
import { TositeKatseleComponentDataResolve } from './_angular/_resolvers/tosite.resolve'
import { KirjanpitajaComponentDataResolve } from './_angular/_resolvers/kirjanpitaja.resolve'
import { TyoajanseurantaComponentDataResolve } from './_angular/_resolvers/tyoajanseuranta.resolve'

import { Subject } from 'rxjs'

import { LemonDialogHeader } from './lemon-dialog-header.component'
import { LemonDateAdapter } from './_jaettu-angular/_material/LemonDateAdapter'

// Tilastot
import { TilastotComponent } from './tilastot/tilastot.component'

// Työajanseuranta
import { TyoajanseurantaComponent } from './tyoaika/tyoajanseuranta.component'
import { TuntilistatComponent } from './tyoaika/tuntilistat.component'
import { YhteenvetoComponent } from './tyoaika/yhteenveto.component'
import { YhteenvetoRuutuComponent } from './tyoaika/yhteenveto-ruutu.component'
import { TyossaolojaksotDialog } from './tyoaika/dialogit/tyossaolojaksot.dialog'

// Kirjanpito
import { KirjanpitoComponent } from './kirjanpito/kirjanpito.component'
import { KirjanpitoLahetysComponent } from './kirjanpito/kirjanpito-lahetys.component'
import { KirjanpitoLahetysSignatureComponent } from './kirjanpito/kirjanpito-lahetys-signature.component'
import { KirjanpitoLahetysAlvComponent } from './kirjanpito/kirjanpito-lahetys-alv.component'
import { KirjanpitoProjektienTyylitComponent } from './kirjanpito/kirjanpito-projektien-tyylit.component'

// Kirjanpito, raportit
import { KirjanpitoRaportitComponent } from './kirjanpito/raportit.component'
import { KirjanpitoRaportitStandaloneComponent } from './kirjanpito/raportit-standalone.component'
import { KirjanpitoRaportitTuloslaskelmaComponent } from './kirjanpito/raportit/tuloslaskelma.component'
import { KirjanpitoRaportitTuloslaskelmaVirallinenComponent } from './kirjanpito/raportit/tuloslaskelma-virallinen.component'
import { KirjanpitoRaportitTaselaskelmaComponent } from './kirjanpito/raportit/taselaskelma.component'
import { KirjanpitoRaportitTaselaskelmaVirallinenComponent } from './kirjanpito/raportit/taselaskelma-virallinen.component'
import { KirjanpitoRaportitPaakirjaComponent } from './kirjanpito/raportit/paakirja.component'
import { KirjanpitoRaportitTuloslaskelmaBruttoComponent } from './kirjanpito/raportit/tuloslaskelma-brutto.component'
import { KirjanpitoRaportitOssComponent } from './kirjanpito/raportit/oss.component'
import { KirjanpitoAlvIlmoitusComponent } from './kirjanpito/alv-ilmoitus.component'
import { KirjanpitoRaportitAlvLaskelmaComponent } from './kirjanpito/raportit/alv-laskelma.component'

// Kirjanpito, tilinpäätös
import { KirjanpitoTilinpaatosComponent } from './kirjanpito/tilinpaatos.component'
import { KirjanpitoTilinpaatosAloitusComponent } from './kirjanpito/tilinpaatos/tilinpaatos-aloitus.component'
import { KirjanpitoTilinpaatosTaseErittelyComponent } from './kirjanpito/tilinpaatos/tase-erittely.component'
import { KirjanpitoTilinpaatosLiitetiedotComponent } from './kirjanpito/tilinpaatos/liitetiedot.component'
import { KirjanpitoTilinpaatosLiitetiedotCheckboxComponent } from './kirjanpito/tilinpaatos/liitetiedot-checkbox.component'
import { KirjanpitoTilinpaatosLiitetiedotMultiInputCheckboxComponent } from './kirjanpito/tilinpaatos/liitetiedot-multi-input-checkbox.component'
import { KirjanpitoTilinpaatosTilintarkastusComponent } from './kirjanpito/tilinpaatos/tilintarkastus.component'
import { KirjanpitoTilinpaatosRekisterointiComponent } from './kirjanpito/tilinpaatos/rekisterointi.component'
import { KirjanpitoTilinpaatosLahetysComponent } from './kirjanpito/tilinpaatos/tilinpaatos-lahetys.component'
import { KirjanpitoTilinpaatosLahetysEmailComponent } from './kirjanpito/tilinpaatos/tilinpaatos-lahetys-email.component'

// Kirjanpito, veroilmoitus
import { KirjanpitoVeroilmoitusComponent } from './kirjanpito/veroilmoitus/veroilmoitus.component'
import { KirjanpitoVeroilmoitusLahetysComponent } from './kirjanpito/veroilmoitus/veroilmoitus-lahetys.component'
import { KirjanpitoVeroilmoitusAikaisemminLahetetytComponent } from './kirjanpito/veroilmoitus/veroilmoitus-aikaisemmin-lahetetyt-spostit.component'
import { KirjanpitoVeroilmoitusTmi2020Component } from './kirjanpito/veroilmoitus/tmi/veroilmoitus-tmi-2020.component'
import { KirjanpitoVeroilmoitusTmi2021Component } from './kirjanpito/veroilmoitus/tmi/veroilmoitus-tmi-2021.component'
import { KirjanpitoVeroilmoitusTmi2022Component } from './kirjanpito/veroilmoitus/tmi/veroilmoitus-tmi-2022.component'
import { KirjanpitoVeroilmoitusTmi2023Component } from './kirjanpito/veroilmoitus/tmi/veroilmoitus-tmi-2023.component'
import { KirjanpitoVeroilmoitusTmi2024Component } from './kirjanpito/veroilmoitus/tmi/veroilmoitus-tmi-2024.component'
import { KirjanpitoVeroilmoitusOy2020Component } from './kirjanpito/veroilmoitus/oy/veroilmoitus-oy-2020.component'
import { KirjanpitoVeroilmoitusOy2021Component } from './kirjanpito/veroilmoitus/oy/veroilmoitus-oy-2021.component'
import { KirjanpitoVeroilmoitusOy2022Component } from './kirjanpito/veroilmoitus/oy/veroilmoitus-oy-2022.component'
import { KirjanpitoVeroilmoitusOy2023Component } from './kirjanpito/veroilmoitus/oy/veroilmoitus-oy-2023.component'
import { KirjanpitoVeroilmoitusOy2024Component } from './kirjanpito/veroilmoitus/oy/veroilmoitus-oy-2024.component'

import { KirjanpitoVeroilmoitusOsakkaatComponent } from './kirjanpito/veroilmoitus/components/veroilmoitus-osakkaat.component'
import { KirjanpitoVeroilmoitusOsakkaatDialog } from './kirjanpito/veroilmoitus/components/veroilmoitus-osakkaat.dialog'
import { KirjanpitoVeroilmoitusPartComponent } from './kirjanpito/veroilmoitus/components/veroilmoitus-part.component'
import { KirjanpitoVeroilmoitusRowNumberComponent } from './kirjanpito/veroilmoitus/components/veroilmoitus-row-number.component'
import { KirjanpitoVeroilmoitusRowNumberDoubleComponent } from './kirjanpito/veroilmoitus/components/veroilmoitus-row-number-double.component'
import { KirjanpitoVeroilmoitusRowRadioComponent } from './kirjanpito/veroilmoitus/components/veroilmoitus-row-radio.component'
import { KirjanpitoVeroilmoitusRowCheckComponent } from './kirjanpito/veroilmoitus/components/veroilmoitus-row-check.component'
import { KirjanpitoVeroilmoitusRowStringComponent } from './kirjanpito/veroilmoitus/components/veroilmoitus-row-string.component'
import { KirjanpitoVeroilmoitusValueReadOnlyComponent } from './kirjanpito/veroilmoitus/components/veroilmoitus-value-read-only.component'
import { KirjanpitoVeroilmoitusRowReadOnlyComponent } from './kirjanpito/veroilmoitus/components/veroilmoitus-row-read-only.component'
import { KirjanpitoVeroilmoitusRowDateComponent } from './kirjanpito/veroilmoitus/components/veroilmoitus-row-date.component'
import { KirjanpitoVeroilmoitusRowDateRangeComponent } from './kirjanpito/veroilmoitus/components/veroilmoitus-row-date-range.component'
import { KirjanpitoVeroilmoitusRowHeaderComponent } from './kirjanpito/veroilmoitus/components/veroilmoitus-row-header.component'

import { VeroilmoitusTranslationService } from './_jaettu-lemonator/service/veroilmoitus/veroilmoitus-translation.service'

// Kirjanpito, tositteet
import { KirjanpitoTositeEsikatseluComponent } from './kirjanpito/tositteet/tosite-esikatselu.component'
import { KirjanpitoTositeKirjaamattomatTositteetComponent } from './kirjanpito/tositteet/tosite-kirjaamattomat.component'
import { KirjanpitoTositeNaytaKirjauksenTositteet } from './kirjanpito/tositteet/tosite-nayta-kirjauksen-tositteet.component'
import { KirjanpitoTositeNayttoComponent } from './kirjanpito/tositteet/tosite-naytto.component'
import { KirjanpitoTositeStandaloneComponent } from './kirjanpito/tositteet/tosite-standalone.component'

import { TositeMuokkaaYliajosummaaDialog } from './kirjanpito/dialogit/tosite.muokkaa-yliajosummaa.dialog'

import { KirjanpitoAineistonLatausComponent } from './kirjanpito/aineiston-lataus/kirjanpito-aineiston-lataus.component'
import { KirjanpitoKuukausiStripComponent } from './kirjanpito/kirjanpito-kuukausistrip.component'
import { KirjanpitoKelloComponent } from './kirjanpito/kirjanpito-kello.component'
import { KirjanpitoKuukausiStripService } from './_angular/service/kirjanpito/kirjanpito-kuukausistrip.service'

import { KirjausPoistaDialog } from './kirjanpito/dialogit/kirjaus.poista.dialog'
import { KirjausPoistotDialog } from './kirjanpito/dialogit/kirjaus.poistot.dialog'
import { KirjausDiagnoseHelpDialog } from './kirjanpito/dialogit/kirjaus.diagnose-help.dialog'
import { KirjausMuutaPaivamaaraDialog } from './kirjanpito/dialogit/kirjaus.muuta-pvm.dialog'
import { KirjausSiirraDialog } from './kirjanpito/dialogit/kirjaus.siirra.dialog'
import { KirjausKopioiDialog } from './kirjanpito/dialogit/kirjaus.kopioi.dialog'
import { KirjausMuutaLaskuperusteiseksiDialog } from './kirjanpito/dialogit/kirjaus.muuta-laskuperusteiseksi.dialog'
import { KirjausJaksotaDialog } from './kirjanpito/dialogit/kirjaus.jaksota.dialog'
import { KirjausJaksotaKuluDialog } from './kirjanpito/dialogit/kirjaus-jaksota-kulu.dialog'
import { KirjausMuokkaaViitenumeroaDialog } from './kirjanpito/dialogit/kirjaus.muokkaa-viitenumeroa.dialog'
import { KirjanpitoAineistonLatausUrlDialog } from './kirjanpito/aineiston-lataus/kirjanpito-aineiston-lataus-url.dialog'
import { KirjanpitoProjektitListausDialog } from './kirjanpito/projektit/projektit-listaus.dialog'
import { KirjanpitoProjektitListausComponent } from './kirjanpito/projektit/projektit-listaus.component'
import { KirjanpitoProjektitDropdownComponent } from './kirjanpito/projektit/projektit-dropdown.component'
import { KirjanpitoReskontraComponent } from './kirjanpito/reskontra/reskontra.component'
import { KirjanpitoReskontraSingleBoxComponent } from './kirjanpito/reskontra/reskontra-single-box.component'
import { KirjanpitoReskontraTriangleComponent } from './kirjanpito/kirjanpito-reskontra-triangle.component'

import { KirjanpitoService } from './_angular/service/kirjanpito/kirjanpito.service'
import { KirjanpitoImageService } from 'app/_angular/service/kirjanpito/kirjanpito-image.service'
import { KirjanpitoImageHandlerService } from 'app/_angular/service/kirjanpito/kirjanpito.service'
import { KirjanpitoTositeService } from 'app/_angular/service/kirjanpito/kirjanpito-tosite.service'
import { KirjanpitoUriService } from 'app/_jaettu-lemonator/service/kirjanpito-uri.service'
import { KirjanpitoJaettuService } from 'app/_jaettu-lemonator/service/kirjanpito-jaettu.service'
import { KirjanpitoRaportitAutosaveService } from 'app/_angular/service/kirjanpito/kirjanpito-raportit-autosave.service'
import { KirjanpitajanAllekirjoitusService } from 'app/_jaettu-lemonator/service/kirjanpitajan-allekirjoitus.service'
import { KirjanpitoReskontraUriService } from 'app/_jaettu-lemonator/service/kirjanpito-reskontra-uri.service'
import { KirjanpitoNavigationService } from './_angular/service/kirjanpito/kirjanpito-navigation.service'
import { KirjanpitoRaportitVertailuTiedotService } from './_angular/service/kirjanpito/kirjanpito-raportit-vertailutiedot.service'

// Tilikartat
import { PaatilikarttaComponent } from './yllapito/tilikartta/paatilikartta.component'
import { PaatilikarttaProfiiliComponent } from './yllapito/tilikartta/profiili-paatilikartta.component'
import { AsiakkaanTilikarttaComponent } from './asiakkaat/tilikartta/asiakkaan-tilikartta.component'
import { AsiakkaanTilikarttaEditComponent } from './asiakkaat/tilikartta/asiakkaan-tilikartta-edit.component'
import { TilikarttaService } from './_angular/service/tilikartta.service'
import { TilikarttaJaettuService } from './_jaettu-lemonator/service/tilikartta-jaettu.service'
import { KirjanpitotiliMuokkaaDialog } from './yllapito/tilikartta/kirjanpitotili-muokkaa.dialog'
import { KirjanpitotiliProfiiliMuokkaaDialog } from './yllapito/tilikartta/profiili-kirjanpitotili-muokkaa.dialog'
import { AsiakkaanKirjanpitotiliMuokkaaDialog } from './asiakkaat/tilikartta/kirjanpitotili-muokkaa.dialog'
import { AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialog } from './asiakkaat/tilikartta/kirjanpitotili-muokkaa-paatilikartan-tilia.dialog'

// Asiakkaat
import { AsiakasOtsikkoComponent } from './asiakkaat/asiakas-otsikko.component'
// import { AsiakasKotiOtsikkoComponent } from './asiakkaat/asiakas-koti-otsikko.component'
import { AsiakasKopioija } from './_angular/service/asiakas/asiakas.kopioija'
import { AsiakasService, LaskuttavaAsiakasLemonatorProvider } from './_angular/service/asiakas/asiakas.service'
import { AsiakasUriService } from './_jaettu-lemonator/service/asiakas-uri.service'
import { AsiakasJaettuService } from './_jaettu-lemonator/service/asiakas-jaettu.service'
import { AsiakasJaettuLemonaidService } from './_jaettu/service/asiakas-jaettu-lemonaid.service'
import { AsiakasTyojonoLoadingService } from 'app/_angular/service/asiakas/asiakas-tyojono-loading.service'
import { LaskuttavaAsiakasProvider } from './_jaettu-angular/service/lasku/laskuttava-asiakas.service'
import { AsiakasLisaaComponent } from './asiakkaat/asiakas-lisaa.component'
import { AsiakasOmaveroMaksutiedotComponent } from './asiakkaat/yllapito/omavero-maksutiedot.component'
import { AsiakasLisaaEkaLaskutuskuukausiComponent } from './asiakkaat/asiakas-lisaa.pvm.component'
import { AsiakkaanKayttajatComponent } from './asiakkaat/asiakkaan-kayttajat.component'
import { AsiakkaanMaksutavatComponent } from './asiakkaat/asiakkaan-maksutavat.component'
import { AsiakkaanMaksutavatValitseKuvakeDialog } from './asiakkaat/asiakkaan-maksutavat.valitse-kuvake.dialog'
import { AsiakkaanTositteetComponent } from './asiakkaat/asiakkaan-tositteet.component'
import { AsiakkaanKayttajatDataSourceService } from './asiakkaat/asiakkaan-kayttajat.datasource.service'
import { AsiakashakuComponent } from './asiakkaat/asiakashaku.component'
import { AsiakasSalesListComponent } from './asiakkaat/sales.component'

import { AsiakasAsetuksetAsiakastiedotComponent } from './asiakkaat/asetukset/asiakastiedot.component'
import { AsiakkaanSopimuskausiUusiDialog } from './asiakkaat/asetukset/sopimuskausi-uusi.dialog'
import { AsiakkaanSopimuskausiPaataDialog } from './asiakkaat/asetukset/sopimuskausi-paata.dialog'
import { AsiakkaanSopimuskausiTaukoDialog } from './asiakkaat/asetukset/sopimuskausi-tauko.dialog'
import { AsiakkaanSopimuskausiTilaIkoni } from './asiakkaat/asetukset/sopimuskausi-tila.ikoni'
import { AsiakasAsetuksetYtjTiedotComponent } from './asiakkaat/asetukset/ytj-tiedot.component'
import { AsiakasAsetuksetLaskutusComponent } from './asiakkaat/asetukset/laskutus.component'
import { AsiakasAsetuksetSopimuskaudetComponent } from './asiakkaat/asetukset/sopimuskaudet.component'
import { AsiakasAsetuksetKirjanpitotiedotComponent } from './asiakkaat/asetukset/kirjanpitotiedot.component'
import { AsiakasAsetuksetTuntemistiedotComponent } from './asiakkaat/asetukset/tuntemistiedot/tuntemistiedot.component'
import { AsiakasAsetuksetRahanpesunSelvityksetComponent } from './asiakkaat/asetukset/tuntemistiedot/tuntemistiedot-lomakkeet/rahanpesun-selvitykset.component'
import { AsiakasAsetuksetRiskiarvioComponent } from './asiakkaat/asetukset/tuntemistiedot/tuntemistiedot-lomakkeet/asiakkaan-riskiarvio.component'
import { AsiakasAsetuksetOsakkaatHallitusEdunsaajatComponent } from './asiakkaat/asetukset/tuntemistiedot/tuntemistiedot-lomakkeet/osakkaat-hallitus-edunsaajat.component'
import { AsiakasTyojonoLoadingDetailsComponent } from './asiakkaat/asetukset/asiakas-tyojono-loading-details.component'

import { KayttajaComponent } from './asiakkaat/kayttaja.component'
import { KayttajaKopioija } from './_angular/service/asiakas/kayttaja.kopioija'

import { AsiakkaanTositteidenLataaminenDialog } from './asiakkaat/asiakkaan-tositteiden-lataaminen.dialog'
import { AsiakkaanTositteidenLataushistoriaDialog } from './asiakkaat/asiakkaan-tositteiden-lataushistoria.dialog'
import { KayttajaVaihdaSalasanaDialog } from './asiakkaat/kayttaja.vaihda-salasana.dialog'
import { KayttajaHaluatkoVarmastiLahettaaTaikalinkinDialog } from './asiakkaat/kayttaja.haluatko-varmasti-lahettaa-taikalinkin.dialog'
import { KayttajaLoginHistoryDialog } from './asiakkaat/kayttaja.login-history.dialog'
import { KayttajaStrongAuthHistoryDialog } from './asiakkaat/kayttaja.strong-auth-history.dialog'

import { AjopaivakirjaContainerComponent } from './asiakkaat/ajopaivakirja/ajopaivakirja-container.component'
import { AjopaivakirjaLemonatorComponent } from './asiakkaat/ajopaivakirja/ajopaivakirja-lemonator.component'
import { UlkopuolisetKulutLemonatorComponent } from './asiakkaat/ajopaivakirja/ulkopuoliset-kulut-lemonator.component'
import { ArkistoComponent } from './asiakkaat/arkisto/arkisto.component'
import { ArkistoUploadDialog } from './asiakkaat/arkisto/arkisto-upload.dialog'
import { ArkistoService } from './_jaettu-lemonator/service/arkisto.service'

// Asiakkaan ylläpito
import { BankConnectionsComponent } from './asiakkaat/yllapito/bank-connections.component'
import { AiiaTilitapahtumatComponent } from './asiakkaat/yllapito/aiia/aiia-tilitapahtumat.component'
import { AiiaTyotComponent } from './asiakkaat/yllapito/aiia/aiia-tyot.component'

import { AsiakkaanApixLaskujenTiedotComponent } from './asiakkaat/yllapito/apix-laskujen-tiedot.component'
import { AsiakkaanLemonaidLaskuasetuksetComponent } from './asiakkaat/yllapito/lemonaid-laskuasetukset.component'
import { AsiakkaanKirjanpitoLahetyksetComponent } from './asiakkaat/yllapito/kirjanpito-lahetykset.component'
import { YtjTiedotNakymaComponent } from './asiakkaat/yllapito/ytj-tiedot/ytj-tiedot-nakyma.component'
import { AsiakkaanKirjanpidonAlustaminenComponent } from './asiakkaat/yllapito/kirjanpidon-alustaminen.component'
import { YhteisomyyntiIlmoituksetComponent } from './asiakkaat/yllapito/yhteisomyynti-ilmoitukset.component'
import { HolviApiQueryComponent } from './asiakkaat/yllapito/holvi-api/holvi-api-query.component'
import { HolviTransactionDetailsDialog } from './asiakkaat/yllapito/holvi-api/holvi-api-transaction-details.dialog'
import { AjastetutTyotComponent } from './asiakkaat/yllapito/ajastetut-tyot.component'

// Asiakaslistaus
import { AsiakkaatComponent } from './asiakkaat/listaus/asiakkaat.component'
import { AsiakkaatComponentOld } from './asiakkaat/listaus/asiakkaat.component-old'
import { AsiakkaatListausComponent } from './asiakkaat/listaus/asiakkaat-listaus.component'
import { AsiakkaatListausComponentOld } from './asiakkaat/listaus/asiakkaat-listaus.component-old'
import { AsiakkaatDataSourceStateService } from './asiakkaat/listaus/asiakkaat.datasource.service'
import { AsiakkaanKirjanpitostatusKuukaudelleDialog } from './asiakkaat/listaus/asiakkaan-kirjanpitostatus-kuukaudelle.dialog'
import { AsiakkaanKirjanpitostatusLisaaTilikausiDialog } from './asiakkaat/listaus/asiakkaan-kirjanpitostatus-lisaa-tilikausi.dialog'
import { AsiakkaanMuistiinpanotDialog } from './asiakkaat/listaus/asiakkaan-muistiinpanot.dialog'

import { KirjanpitajanSarakkeenMuokkausDialog } from './asiakkaat/listaus/kirjanpitajan-sarakkeen-muokkaus.dialog'
import { AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialog } from './asiakkaat/listaus/asiakkaan-kirjanpitajan-sarakkeen-arvon-muokkaus.dialog'

// Laskut
import { LaskuKopioija } from './_jaettu/service/lasku/lasku.kopioija'
import { LaskutComponent } from './laskut/laskut.component'
import { LaskuSummatService, LaskutSelaaComponent } from './laskut/selaa/laskut-selaa.component'
import { LaskutPiilotetutComponent } from './laskut/selaa/laskut-piilotetut.component'
import { LaskuComponent } from './laskut/lasku.component'
import { LaskuRivitComponent } from './_jaettu-angular/laskut/muokkaa/lasku-rivit.component'
import { LaskuRiviComponent } from './_jaettu-angular/laskut/muokkaa/lasku-rivi.component'
import { LaskuYlaosanDropparitComponent } from './_jaettu-angular/laskut/muokkaa/lasku-ylaosan-dropparit.component'
import { LaskuAsiakkaanTiedotComponent } from './_jaettu-angular/laskut/muokkaa/lasku-asiakkaan-tiedot.component'
import { LaskuMuutTiedotComponent } from './_jaettu-angular/laskut/muokkaa/lasku-muut-tiedot.component'
import { TuoteHakuComponent } from './_jaettu-angular/laskut/tuotteet/tuote-haku.component'
import { LaskuPdfEsikatseluPerinteinen } from './_jaettu-angular/laskut/esikatselu/pdf.perinteinen.component'
import { LaskuPdfEsikatseluPerinteinenRajoitettuLeveyteenLemonator } from './_angular/_components/lasku.perinteinen-rajoitettu-leveyteen.component'
import { LaskuEmailEsikatseluPerinteinen } from './_jaettu-angular/laskut/esikatselu/email.perinteinen.component'
import { LaskuUriService } from './_jaettu/service/lasku/lasku-uri.service'
import { LaskuSharedService, ReskontraService } from './_jaettu/service/lasku/lasku-shared.service'
import { LaskuKorkoService } from './_jaettu/service/lasku/lasku-korko.service'
import { LaskuIndeksoija } from './_jaettu/service/lasku/lasku.indeksoija'
import { KonfiguraatioPalvelu } from './_jaettu-angular/service/lasku/configuration.service'
import { AlvNumeronTarkistajaService } from './_jaettu-angular/service/vat-number-check.service'
import { LaskunAsiakasTypeaheadBase, LaskunTuoteTypeaheadBase } from './_jaettu-angular/service/lasku/typeahead.service'
import { LaskuService } from './_angular/service/lasku/lasku.service'
import { SahkoisenLaskunValittajaService } from './_jaettu/service/lasku/sahkoisen-laskun-valittaja.service'
import { LaskunAsiakasTypeahead, LaskunTuoteTypeahead } from './_angular/service/lasku/lasku.typeahead'
import { LaskuSpostiDialog } from './laskut/dialogit/lasku.sposti.dialog'
import { LaskuKokoVirheDialog } from './laskut/dialogit/lasku.koko-virhe.dialog'
import { LaskuLataaDialog } from './laskut/dialogit/lasku.lataa.dialog'
import { LahetaMuistutusDialog } from './yllapito/maksumuistutus/dialogit/laheta.dialog'
import { LaskuLataaLahetystiedostaDialog } from './laskut/dialogit/lasku.lataa-lahetystiedosta.dialog'
import { LaskuSpostiService } from './_jaettu/service/lasku/lasku-sposti.service'
// import { LaskutFirestoreDataSource } from './laskut/laskut.firestore.datasource'
import { LaskutUusiDataSourceService } from './laskut/laskut.uusi.datasource.service'
import { LaskutSelaaDataSourceService } from './laskut/selaa/laskut-selaa.datasource.service'
import { LaskutPiilotetutDataSourceService } from './laskut/selaa/laskut-piilotetut.datasource.service'
import { LaskuKatseleComponent } from './laskut/lasku.katsele.component'
import { LaskuSelaaKatseleComponent } from './laskut/selaa/lasku-selaa.katsele.component'
import { LaskuLahetystietojenLuoja } from './_jaettu/service/lasku/lasku-lahetystietojen.luoja'
import { LaskuReskontraTiedotDialog } from './laskut/dialogit/lasku.reskontra-tiedot.dialog'

// Ylläpito
import { MaksumuistutusComponent } from './yllapito/maksumuistutus/maksumuistutus.component'
import { MaksumuistutusDataSourceService } from './yllapito/maksumuistutus/maksumuistutus.datasource.service'
import { YhteisomyyntilaskutComponent } from './yllapito/yhteisomyyntilaskut.component'
import { YhteisomyyntilaskujenLataaminenDialog } from './yllapito/yhteisomyyntilaskujen-lataaminen.dialog'
import { KirjanpitajatComponent } from './yllapito/kirjanpitajat/kirjanpitajat.component'
import { KirjanpitajaComponent } from './yllapito/kirjanpitajat/kirjanpitaja.component'
import { HolviImportComponent } from './yllapito/holvi-import/holvi-import.component'
import { YllapitoVerohallintoComponent } from './yllapito/integraatiot/verohallinto.component'
import { YllapitoHolviComponent } from './yllapito/integraatiot/holvi.component'
import { KayttoehdotComponent } from './yllapito/kayttoehdot/kayttoehdot.component'
import { KayttoehtojenJulkaiseminenDialog } from './yllapito/kayttoehdot/kayttoehtojen-julkaiseminen.dialog'
import { SopimuksetComponent } from './yllapito/sopimukset/sopimukset.component'
import { SopimuksenJulkaiseminenDialog } from './yllapito/sopimukset/sopimuksen-julkaiseminen.dialog'
import { DmarcRaportitComponent } from './yllapito/postmarkapp/dmarc-raportit.component'
import { DmarcRaporttiComponent } from './yllapito/postmarkapp/dmarc-raportti.component'
import { PankkienListausComponent } from './yllapito/pankkiyhteydet/pankkien-listaus.component'
// import { PankkienSyncStatusComponent } from './yllapito/pankkiyhteydet/deprecated/pankkien-sync-status.component'
import { PankkiyhteydetComponent } from './yllapito/pankkiyhteydet/pankkiyhteydet.component'
import { ReportsComponent } from './yllapito/raportit/reports.component'
import { EmailTemplatesComponent } from './yllapito/email-templates/email-templates.component'
import { YllapitoAjastetutTyotComponent } from './yllapito/ajastetut-tyot/yllapito-ajastetut-tyot.component'
import { AjastetutTyotDataDialog } from './yllapito/ajastetut-tyot/ajastetut-tyot-data.dialog'

// Kirjanpitäjä
import { KirjanpitajanAsetuksetComponent } from './kirjanpitaja/kirjanpitajan-asetukset.component'

// Kirjanpitäjät
import { KirjanpitajaService } from './_angular/service/kirjanpitaja/kirjanpitaja.service'
import { KirjanpitajaKopioija } from './_angular/service/kirjanpitaja/kirjanpitaja.kopioija'
import { KirjanpitajanToimipisteService } from './_jaettu-lemonator/service/kirjanpitajan-toimipiste.service'

// Tositteet, selailu
import { TositeSelailuListausComponent } from './tositteet/selailu/tosite.listaus.component'
import { TositeSelailuKatseleComponent } from './tositteet/selailu/tosite.katsele.component'
import { TositeSelailuPoistaDialog } from './tositteet/selailu/tosite.poista.dialog'
import { TuontiKaynnistettyUudelleenDialog } from './tositteet/selailu/tuonti-kaynnistetty-uudelleen.dialog'
import { TositeSelailuListausFirebaseDataSource } from './tositteet/selailu/tosite.listaus.firebase.datasource'
import { TositeUriService } from './_jaettu/service/tosite/tosite-uri.service'
import { TositeIndeksoija } from './_jaettu/service/tosite/tosite.indeksoija'
import { TositeKopioija } from './_jaettu/service/tosite/tosite.kopioija'
import { TositeKuvaService } from './_angular/service/tosite/tosite-kuva.service'
import { TositeService } from './_angular/service/tosite/tosite.service'

// Tilitapahtumat
import { TilitapahtumatComponent } from './yllapito/tilitapahtumat/tilitapahtumat.component'
import { TilitapahtumatUusiDataSourceService } from './yllapito/tilitapahtumat/tilitapahtumat.uusi.datasource.service'
import { TilitapahtumanTiedotDialog } from './yllapito/tilitapahtumat/tilitapahtuma-tiedot.dialog'
import { TilitapahtumienKohdistusdebugComponent } from './yllapito/tilitapahtumat/tilitapahtumien-kohdistusdebug.component'

import { PaivasaldoListausComponent } from './yllapito/tilitapahtumat/paivasaldot-listaus.component'

import { KirjanpitajanAsiakkaidenLaskutDataSourceService } from './yllapito/kirjanpitajan-asiakkaiden-laskut.datasource.service'
import { EmailTemplatesUriService } from './_jaettu-lemonator/service/email-templates-uri.service'
import { BankConsentUriService } from './_jaettu/service/bank-consent-uri.service'

import {
  AsiakasJaettuServiceAngular,
  AsiakasUriServiceAngular,
  KirjanpitoJaettuServiceAngular,
  KirjanpitoUriServiceAngular,
  TilikarttaJaettuServiceAngular,
  YllapitoUriServiceAngular,
  PyhapaivatServiceAngular,
  TyoajanseurantaServiceAngular,
  VeroilmoitusTranslationServiceAngular,
  KirjanpitajanAllekirjoitusServiceAngular,
  KirjanpitajanToimipisteServiceAngular,
  HolviSharedUriServiceAngular,
  TilinpaatosTranslationServiceAngular,
  TilinpaatosLiitetiedotJaettuServiceAngular,
  KirjanpitajanTiimiServiceAngular,
  AsiakkaanSopimuskausiServiceAngular,
  ArkistoServiceAngular,
  EmailTemplatesUriServiceAngular,
  VahvistettuKorkoServiceAngular,
  KirjanpitoReskontraUriServiceAngular,
  TilinpaatosUsersServiceAngular,
  VeroilmoitusLaskentaServiceAngular,
  PoistotServiceAngular
} from './_angular/angularified-services'

import { ImageZoomComponent } from './_angular/_components/image-zoom.component'
import { PdfViewerComponent } from './_angular/_components/pdf-viewer.component'
import { ImageViewerComponent } from './_angular/_components/image-viewer.component'
import { ImageViewerComponentMultiple } from './_angular/_components/image-viewer-multiple.component'

// Quill text editor
import { QuillModule } from 'ngx-quill'

import { EnvironmentType } from './app.environment'

SentryInit({
  release: 'lemonator@' + environment.version,
  environment: environment.environment === EnvironmentType.PRODUCTION ? 'production' : 'beta',
  dsn: 'https://580a66a181464c5183d30974f6cccd1d@sentry.io/1222230',
  integrations: [
    breadcrumbsIntegration({ console: false }),
    browserTracingIntegration()
  ],
  /** The percentage of how much traces to send. */
  tracesSampleRate: 0.2
})

export class SentryErrorHandler implements ErrorHandler {
  constructor(
    private _snackbar: MatSnackBar
  ) { }
  handleError(err: any): void {

    // Try local logging to console
    try {
      if (console) {
        console.error(err)
      }
    } catch (e) { }

    this._snackbar.open('TAPAHTUI VIRHE: OLE YSTÄVÄLLINEN JA ILMOITA TÄSTÄ YLLÄPIDOLLE. ENNEN JATKAMISTA, OLE HYVÄ JA LATAA SIVU UUDELLEEN.')

    try {
      if (environment.airbrakeEnabled) {

        const error = err.originalError || err

        if (
          Object.prototype.toString.call(error) === '[object Error]' ||
          Object.prototype.toString.call(error) === '[object ErrorEvent]' ||
          Object.prototype.toString.call(error) === '[object ProgressEvent]'
        ) {
          captureException(error)
        } else {
          captureMessage(error.error || error)
        }

      }
    } catch (error) {
      try {
        if (console) {
          console.error(err)
        }
      } catch (e) { }
    }

    try {
      // Try to avoid deep loop
      const debugCtx = err['ngDebugContext']
      const changeDetectorRef = debugCtx && debugCtx.injector.get(ChangeDetectorRef)
      if (changeDetectorRef) {
        changeDetectorRef.detach()
      }
    } catch (error) {
      try {
        if (console) {
          console.error(err)
        }
      } catch (e) { }
    }

  }
}

@Injectable()
export class MyMaterialMatStepperIntl implements MatStepperIntl {

  /**
   * Stream that emits whenever the labels here are changed. Use this to notify
   * components if the labels have changed after initialization.
   */
  changes: Subject<void> = new Subject()

  optionalLabel = ''
  completedLabel = ''
  editableLabel = ''

  constructor(
    private lemonTranslationService: LemonTranslationService
  ) {
    lemonTranslationService.currentLanguageObservable.subscribe(kieli => {
      this.optionalLabel = this.lemonTranslationService.lokalisoiKielella('stepper.optional', kieli)
      this.completedLabel = this.lemonTranslationService.lokalisoiKielella('stepper.completed', kieli)
      this.editableLabel = this.lemonTranslationService.lokalisoiKielella('stepper.editable', kieli)
      this.changes.next()
    })
  }

}

@Injectable()
export class MyMaterialMatSortHeaderIntl implements MatSortHeaderIntl {

  /**
   * Stream that emits whenever the labels here are changed. Use this to notify
   * components if the labels have changed after initialization.
   */
  changes: Subject<void> = new Subject()

  /** ARIA label for the sorting button. */
  sortButtonLabel(id: string): string {
    return '// TODO: FIXME: IMPLEMENT ME'
  }

  /** A label to describe the current sort (visible only to screenreaders). */
  sortDescriptionLabel(id: string, direction: SortDirection): string {
    return '// TODO: FIXME: IMPLEMENT ME'
  }

  constructor(
    private lemonTranslationService: LemonTranslationService
  ) {
    // lemonTranslationService.currentLanguageObservable.subscribe(kieli => {
    // this.changes.next()
    // })
  }

}

@Injectable()
export class MyMaterialPagingIntl implements MatPaginatorIntl {

  /**
   * Stream that emits whenever the labels here are changed. Use this to notify
   * components if the labels have changed after initialization.
   */
  changes: Subject<void> = new Subject()

  /** A label for the page size selector. */
  itemsPerPageLabel: string

  /** A label for the button that increments the current page. */
  nextPageLabel: string

  /** A label for the button that decrements the current page. */
  previousPageLabel: string

  /** A label for the button that moves to the first page. */
  firstPageLabel: string

  /** A label for the button that moves to the last page. */
  lastPageLabel: string

  constructor(
    private lemonTranslationService: LemonTranslationService
  ) {
    lemonTranslationService.currentLanguageObservable.subscribe(kieli => {
      this.itemsPerPageLabel = this.lemonTranslationService.lokalisoiKielella('listaus.kpl-per-sivu', kieli)
      this.nextPageLabel = this.lemonTranslationService.lokalisoiKielella('listaus.seuraava-sivu', kieli)
      this.previousPageLabel = this.lemonTranslationService.lokalisoiKielella('listaus.edellinen-sivu', kieli)
      this.firstPageLabel = this.lemonTranslationService.lokalisoiKielella('listaus.ensimmainen-sivu', kieli)
      this.lastPageLabel = this.lemonTranslationService.lokalisoiKielella('listaus.viimeinen-sivu', kieli)
      this.changes.next()
    })
  }

  /** A label for the range of items within the current page and the length of the whole list. */
  getRangeLabel(page: number, pageSize: number, length: number): string {
    const lengthString = length === Number.MAX_SAFE_INTEGER ? '?' : '' + length


    if (length === 0 || pageSize === 0) {
      return '0 / ' + lengthString
    }
    length = Math.max(length, 0)
    const startIndex = page * pageSize
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize
    return startIndex + 1 + ' - ' + endIndex + ' / ' + lengthString
  }
}

/**
 * Does not detach any subtrees. Reuses routes as long as their route config is the same.
 */
// @Injectable()
// export class LemonRouteReuseStrategy implements RouteReuseStrategy {

//   //   // private storepaths = ['', 'laskutus/tuotteet', 'laskutus/asiakkaat']
//   private cache = new Map<string, { route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle }>()

//   shouldDetach(route: ActivatedRouteSnapshot): boolean {
//     const key = this.key(route.routeConfig.path)
//     // console.log('SHOULD DETACH "' + key + '"')
//     return key === 'asiakkaat'
//   }

//   store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
//     if (route && route.routeConfig && route.routeConfig.path) {
//       const key = this.key(route.routeConfig.path)
//       if (detachedTree === null) {
//         // console.log('DELETE "' + key + '"')
//         // this.cache.delete(key)
//       } else if (key === 'asiakkaat') {
//         // console.log('STORE "' + key + '"')
//         this.cache.set(key, { route: route, detachedTree: detachedTree })
//       }
//     }
//   }

//   shouldAttach(route: ActivatedRouteSnapshot): boolean {
//     const key = this.key(route.routeConfig.path)
//     const attach: boolean = this.cache.get(key) !== null && this.cache.get(key) !== undefined
//     // console.log('SHOULD ATTACH ' + attach + ' "' + key + '"')
//     return attach
//   }

//   retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
//     if (route && route.routeConfig) {
//       const key = this.key(route.routeConfig.path)
//       const fromCache = this.cache.get(key)
//       // console.log('RETRIEVE "' + key + '"', fromCache)
//       return fromCache ? fromCache.detachedTree : null
//     }
//     return null
//   }

//   shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
//     return false
//   }

//   private key(key: string): string {
//     if (key === '') {
//       // return 'root'
//       return 'asiakkaat'
//     }
//     return key
//   }

// }

import localeFi from '@angular/common/locales/fi'

import { TilinpaatosTranslationService } from './_jaettu-lemonator/service/tilinpaatos-translation.service'
import { TilinpaatosLiitetiedotJaettuService } from './_jaettu-lemonator/service/tilinpaatos-liitetiedot-jaettu.service'
import { SharedFirebaseLemonaid } from './_jaettu-angular/base-firebase.service'
import { KirjanpitajanTiimiService } from './_jaettu-lemonator/service/kirjanpitajan-tiimi.service'
import { AsiakkaanSopimuskausiService } from './_jaettu-lemonator/service/sopimuskausi.service'
import { TilinpaatosUriService } from './_jaettu/service/tilinpaatos-uri.service'
import { PoistotService } from './_jaettu-lemonator/service/poistot.service'
import { TilinpaatosUsersService } from './_jaettu-lemonator/service/tilinpaatos-users.service'
import { ApixUriService } from './_jaettu/service/apix-uri.service'
import { VeroilmoitusLaskentaService } from './_jaettu-lemonator/service/veroilmoitus/veroilmoitus-laskenta.service'

registerLocaleData(localeFi)

@NgModule({
  declarations: [
    AppComponent,
    AppMenuComponent,
    LoginComponent,
    LoggedOutComponent,
    // Kirjanpito
    KirjanpitoComponent,
    KirjanpitoLahetysComponent,
    KirjanpitoLahetysSignatureComponent,
    KirjanpitoLahetysAlvComponent,
    KirjanpitoProjektienTyylitComponent,
    // Kirjanpito, raportit
    KirjanpitoRaportitComponent,
    KirjanpitoRaportitStandaloneComponent,
    KirjanpitoRaportitTuloslaskelmaComponent,
    KirjanpitoRaportitTuloslaskelmaVirallinenComponent,
    KirjanpitoRaportitTaselaskelmaComponent,
    KirjanpitoRaportitTaselaskelmaVirallinenComponent,
    KirjanpitoRaportitPaakirjaComponent,
    KirjanpitoRaportitTuloslaskelmaBruttoComponent,
    KirjanpitoRaportitOssComponent,
    KirjanpitoRaportitAlvLaskelmaComponent,
    KirjanpitoAlvIlmoitusComponent,
    // Kirjanpito, tilinpäätös
    KirjanpitoTilinpaatosComponent,
    KirjanpitoTilinpaatosAloitusComponent,
    KirjanpitoTilinpaatosTaseErittelyComponent,
    KirjanpitoTilinpaatosLiitetiedotComponent,
    KirjanpitoTilinpaatosLiitetiedotCheckboxComponent,
    KirjanpitoTilinpaatosLiitetiedotMultiInputCheckboxComponent,
    KirjanpitoTilinpaatosTilintarkastusComponent,
    KirjanpitoTilinpaatosRekisterointiComponent,
    KirjanpitoTilinpaatosLahetysComponent,
    KirjanpitoTilinpaatosLahetysEmailComponent,
    // Kirjanpito, veroilmoituskomponentit
    KirjanpitoVeroilmoitusComponent,
    KirjanpitoVeroilmoitusLahetysComponent,
    KirjanpitoVeroilmoitusAikaisemminLahetetytComponent,
    KirjanpitoVeroilmoitusPartComponent,
    KirjanpitoVeroilmoitusRowNumberComponent,
    KirjanpitoVeroilmoitusRowNumberDoubleComponent,
    KirjanpitoVeroilmoitusRowRadioComponent,
    KirjanpitoVeroilmoitusRowCheckComponent,
    KirjanpitoVeroilmoitusRowStringComponent,
    KirjanpitoVeroilmoitusRowReadOnlyComponent,
    KirjanpitoVeroilmoitusValueReadOnlyComponent,
    KirjanpitoVeroilmoitusRowDateComponent,
    KirjanpitoVeroilmoitusRowDateRangeComponent,
    KirjanpitoVeroilmoitusRowHeaderComponent,
    KirjanpitoVeroilmoitusOsakkaatComponent,
    KirjanpitoVeroilmoitusOsakkaatDialog,
    // Kirjanpito, Veroilmoitukset
    KirjanpitoVeroilmoitusTmi2020Component,
    KirjanpitoVeroilmoitusTmi2021Component,
    KirjanpitoVeroilmoitusTmi2022Component,
    KirjanpitoVeroilmoitusTmi2023Component,
    KirjanpitoVeroilmoitusTmi2024Component,
    KirjanpitoVeroilmoitusOy2020Component,
    KirjanpitoVeroilmoitusOy2021Component,
    KirjanpitoVeroilmoitusOy2022Component,
    KirjanpitoVeroilmoitusOy2023Component,
    KirjanpitoVeroilmoitusOy2024Component,
    // Kirjanpito, tositteet
    KirjanpitoTositeEsikatseluComponent,
    KirjanpitoTositeKirjaamattomatTositteetComponent,
    KirjanpitoTositeNaytaKirjauksenTositteet,
    KirjanpitoTositeNayttoComponent,
    KirjanpitoTositeStandaloneComponent,
    TositeMuokkaaYliajosummaaDialog,
    KirjausPoistaDialog,
    KirjausPoistotDialog,
    KirjausDiagnoseHelpDialog,
    KirjausMuutaPaivamaaraDialog,
    KirjausSiirraDialog,
    KirjausKopioiDialog,
    KirjausMuutaLaskuperusteiseksiDialog,
    KirjausJaksotaDialog,
    KirjausJaksotaKuluDialog,
    KirjausMuokkaaViitenumeroaDialog,
    KirjanpitoAineistonLatausUrlDialog,
    KirjanpitoAineistonLatausComponent,
    KirjanpitoKuukausiStripComponent,
    KirjanpitoKelloComponent,
    KirjanpitoProjektitDropdownComponent,
    KirjanpitoProjektitListausDialog,
    KirjanpitoProjektitListausComponent,
    KirjanpitoReskontraComponent,
    KirjanpitoReskontraSingleBoxComponent,
    KirjanpitoReskontraTriangleComponent,
    // Tilikartat
    PaatilikarttaComponent,
    PaatilikarttaProfiiliComponent,
    AsiakkaanTilikarttaComponent,
    AsiakkaanTilikarttaEditComponent,
    KirjanpitotiliMuokkaaDialog,
    KirjanpitotiliProfiiliMuokkaaDialog,
    AsiakkaanKirjanpitotiliMuokkaaDialog,
    AsiakkaanKirjanpitotiliMuokkaaPaatilikartanTiliaDialog,
    // Tilastot
    TilastotComponent,
    // Työajanseuranta
    TyoajanseurantaComponent,
    TuntilistatComponent,
    YhteenvetoComponent,
    YhteenvetoRuutuComponent,
    TyossaolojaksotDialog,
    // Asiakkaat
    AsiakasOtsikkoComponent,
    // AsiakasKotiOtsikkoComponent,
    AsiakkaatComponent,
    AsiakkaatComponentOld,
    AsiakkaatListausComponent,
    AsiakkaatListausComponentOld,
    // Asiakkaan asetukset
    AsiakasAsetuksetKirjanpitotiedotComponent,
    AsiakasAsetuksetLaskutusComponent,
    AsiakasAsetuksetSopimuskaudetComponent,
    AsiakasAsetuksetAsiakastiedotComponent,
    AsiakkaanSopimuskausiUusiDialog,
    AsiakkaanSopimuskausiPaataDialog,
    AsiakkaanSopimuskausiTaukoDialog,
    AsiakkaanSopimuskausiTilaIkoni,
    AsiakasAsetuksetYtjTiedotComponent,
    AsiakasAsetuksetTuntemistiedotComponent,
    AsiakasAsetuksetRahanpesunSelvityksetComponent,
    AsiakasAsetuksetRiskiarvioComponent,
    AsiakasAsetuksetOsakkaatHallitusEdunsaajatComponent,
    AsiakasTyojonoLoadingDetailsComponent,
    // Asiakkaan ajopäiväkirja/kirjanpidon ulkopuoliset kulut Lemonaidista
    AjopaivakirjaContainerComponent,
    AjopaivakirjaLemonatorComponent,
    UlkopuolisetKulutLemonatorComponent,
    ArkistoComponent,
    ArkistoUploadDialog,
    // Asiakkaan pankkiyhteydet
    BankConnectionsComponent,
    AiiaTilitapahtumatComponent,
    AiiaTyotComponent,
    // Asiakkaan ylläpito
    AsiakkaanApixLaskujenTiedotComponent,
    AsiakkaanLemonaidLaskuasetuksetComponent,
    AsiakkaanKirjanpitoLahetyksetComponent,
    AsiakasLisaaComponent,
    AsiakasOmaveroMaksutiedotComponent,
    AsiakasLisaaEkaLaskutuskuukausiComponent,
    AsiakkaanKayttajatComponent,
    AsiakkaanMaksutavatComponent,
    AsiakkaanMaksutavatValitseKuvakeDialog,
    AsiakashakuComponent,
    AsiakasSalesListComponent,
    AsiakkaanTositteetComponent,
    KayttajaComponent,
    AsiakkaanTositteidenLataaminenDialog,
    AsiakkaanTositteidenLataushistoriaDialog,
    KayttajaVaihdaSalasanaDialog,
    KayttajaHaluatkoVarmastiLahettaaTaikalinkinDialog,
    KayttajaLoginHistoryDialog,
    KayttajaStrongAuthHistoryDialog,
    AsiakkaanKirjanpitostatusKuukaudelleDialog,
    AsiakkaanKirjanpitostatusLisaaTilikausiDialog,
    AsiakkaanMuistiinpanotDialog,
    KirjanpitajanSarakkeenMuokkausDialog,
    AsiakkaanKirjanpitajanSarakkeenArvonMuokkausDialog,
    TilitapahtumatComponent,
    TilitapahtumienKohdistusdebugComponent,
    TilitapahtumanTiedotDialog,
    PaivasaldoListausComponent,
    // Laskut
    LaskuComponent,
    LaskutComponent,
    LaskutSelaaComponent,
    LaskutPiilotetutComponent,
    // Ylläpito
    MaksumuistutusComponent,
    YhteisomyyntilaskutComponent,
    DmarcRaporttiComponent,
    DmarcRaportitComponent,
    YhteisomyyntilaskujenLataaminenDialog,
    KirjanpitajatComponent,
    KirjanpitajaComponent,
    HolviImportComponent,
    YllapitoVerohallintoComponent,
    YllapitoHolviComponent,
    KayttoehdotComponent,
    KayttoehtojenJulkaiseminenDialog,
    SopimuksetComponent,
    SopimuksenJulkaiseminenDialog,
    PankkiyhteydetComponent,
    PankkienListausComponent,
    YtjTiedotNakymaComponent,
    AsiakkaanKirjanpidonAlustaminenComponent,
    YhteisomyyntiIlmoituksetComponent,
    AjastetutTyotComponent,
    HolviApiQueryComponent,
    HolviTransactionDetailsDialog,
    ReportsComponent,
    EmailTemplatesComponent,
    YllapitoAjastetutTyotComponent,
    AjastetutTyotDataDialog,

    // Kirjanpitäjä
    KirjanpitajanAsetuksetComponent,
    // Ostotositteiden komponentit
    TositeSelailuListausComponent,
    TositeSelailuKatseleComponent,
    TositeSelailuPoistaDialog,
    TuontiKaynnistettyUudelleenDialog,
    // Dialogit
    LemonDialogHeader,
    VaihdaKieliDialog,
    ChangelogDialog,
    ServiceWorkerCacheInfoDialog,
    VirheDialog,
    AreYouSureDialog,
    InfoDialog,
    KirjanpitoValmisDialog,
    KirjanpitoLisaaTiliotteenSaldoDialog,
    TaukoMuistutusDialog,
    // Yleiset
    BarcodeComponent,
    QrcodeComponent,
    LoadingComponent,
    LemonNumberOnlyDirective,
    MonthPickerDirective,
    NumberDatePickerDirective,
    RightClickMatMenuDirective,
    TimeFormatDirective,
    PlaceholderWithRequiredIndicatorDirective,
    ToggleArrow,
    CountToDirective,
    ResizedDirective,
    OnDisabledDirective,
    ConfettiRain,
    ImageZoomComponent,
    PdfViewerComponent,
    ImageViewerComponent,
    ImageViewerComponentMultiple,
    BrowserClockCheckComponent,
    // MultiSelectorComponent,
    // Pipes
    CurrencyPipe,
    BigCurrencyPipe,
    CurrencyWithLocalePipe,
    BigCurrencyWithLocalePipe,
    LemonKaannaPipe,
    LemonKaannaWithLocalePipe,
    LemonatorKaannaTilinpaatosPipe,
    LemonDatePipe,
    LemonDateAndTimePipe,
    LemonDateWithLocalePipe,
    LemonLocalDatePipe,
    LemonNumberDateWithLocaleNarrowPipe,
    LemonNumberDatePipe,
    LemonNumberDateWithLocalePipe,
    LemonLocalDateWithLocalePipe,
    LemonTimestampPipe,
    LemonTimestampWithLocalePipe,
    LemonTimestampDateAndTimePipe,
    LemonTimestampDateAndTimeWithLocalePipe,
    LemonLocalToMonthAndYearPipe,
    LemonNumberMonthToMonthAndYearPipe,
    LemonMaaPipe,
    LemonMaaWithlocalePipe,
    DecimalPipe,
    DecimalWithLocalePipe,
    BigDecimalPipe,
    BigDecimalWithLocalePipe,
    PrettyPrintJsonPipe,
    LemonatorNumberDateToDatePipe,
    // Laskut
    LaskuRiviComponent,
    LaskuRivitComponent,
    LaskuYlaosanDropparitComponent,
    LaskuAsiakkaanTiedotComponent,
    LaskuMuutTiedotComponent,
    TuoteHakuComponent,
    LaskuPdfEsikatseluPerinteinen,
    LaskuPdfEsikatseluPerinteinenRajoitettuLeveyteenLemonator,
    LaskuEmailEsikatseluPerinteinen,
    LaskuSpostiDialog,
    LaskuReskontraTiedotDialog,
    LaskuKokoVirheDialog,
    LaskuLataaDialog,
    LaskuLataaLahetystiedostaDialog,
    LaskuKatseleComponent,
    LaskuSelaaKatseleComponent,
    // Maksumuistutus
    LahetaMuistutusDialog
  ],
  bootstrap: [AppComponent],
  imports: [
    NgxFileDropModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    // PdfViewerModule,
    // CDK below this
    CdkTableModule,
    ScrollingModule,
    DragDropModule,
    ClipboardModule,
    // Material below this
    MatInputModule,
    MatListModule,
    MatStepperModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatRadioModule,
    MatSnackBarModule,
    MatTreeModule,
    MatExpansionModule,
    MatSlideToggleModule,
    // Quill text editor
    QuillModule.forRoot({
      modules: {
        toolbar: [
          [{ 'header': 1 }, { 'header': 2 }],
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          // [{'script': 'sub'}, {'script': 'super' }],
          [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
          ['clean']
        ]
      }
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fi-FI' },
    // Guards
    AuthGuard,
    AsiakasGuard,
    YllapitoGuard,
    // Resolvit
    TositeKatseleComponentDataResolve,
    AsiakasComponentDataResolve,
    LaskuComponentDataResolve,
    LaskuKatseleComponentDataResolve,
    LaskuSelaaKatseleComponentDataResolve,
    KayttajaComponentDataResolve,
    KirjanpitoComponentDataResolve,
    KirjanpitajaComponentDataResolve,
    TyoajanseurantaComponentDataResolve,
    // Services
    // Vain webin käytössä olevat palvelut
    DebugService,
    LemonHttpService,
    KirjautunutKayttajaService,
    LemonTranslationService,
    MaaService,
    PdfService,
    VasenValikkoService,
    FormValidationService,
    CookieService,
    // CacheService,
    WindowSizeService,
    LadataanService,
    TimestampService,
    DragAndDropService,
    TiedostojenLataamisService,
    VersionTarkistusPalvelu,
    TilikarttaService,
    Aikakone,
    KirjanpitoKelloService,
    AlvLemonatorService,
    FileSaverService,
    { provide: AlvService, useExisting: AlvLemonatorService },
    { provide: TilikarttaJaettuService, useClass: TilikarttaJaettuServiceAngular },
    // Shared
    { provide: BicService, useClass: BicServiceAngular },
    { provide: IbanService, useClass: IbanServiceAngular },
    { provide: ViitenumeroService, useClass: ViitenumeroServiceAngular },
    { provide: CurrencyService, useClass: CurrencyServiceAngular },
    { provide: DateService, useClass: DateServiceAngular },
    { provide: FirestoreIndeksoija, useClass: FirestoreIndeksoijaAngular },
    { provide: KopioijaPalvelu, useClass: KopioijaPalveluAngular },
    { provide: CodeCheckService, useClass: CodeCheckServiceAngular },
    { provide: TranslationService, useClass: TranslationServiceAngular },
    { provide: StringService, useClass: StringServiceAngular },
    { provide: YllapitoUriService, useClass: YllapitoUriServiceAngular },
    { provide: PyhapaivatService, useClass: PyhapaivatServiceAngular },
    { provide: TyoajanseurantaService, useClass: TyoajanseurantaServiceAngular },
    { provide: KirjanpitajanToimipisteService, useClass: KirjanpitajanToimipisteServiceAngular },
    { provide: KycUriService, useClass: KycUriServiceAngular },
    { provide: TilinpaatosUriService, useClass: TilinpaatosUriServiceAngular },
    { provide: PoistotService, useClass: PoistotServiceAngular },
    { provide: TilinpaatosUsersService, useClass: TilinpaatosUsersServiceAngular },
    { provide: VatNumberValidationService, useClass: VatNumberValidationServiceAngular },
    { provide: HolviSharedUriService, useClass: HolviSharedUriServiceAngular },
    { provide: TilinpaatosTranslationService, useClass: TilinpaatosTranslationServiceAngular },
    { provide: TilinpaatosLiitetiedotJaettuService, useClass: TilinpaatosLiitetiedotJaettuServiceAngular },
    { provide: AjastettuTyoService, useClass: AjastettuTyoServiceAngular },
    { provide: AsiakkaanSopimuskausiService, useClass: AsiakkaanSopimuskausiServiceAngular },
    { provide: ArkistoService, useClass: ArkistoServiceAngular },
    { provide: EmailTemplatesUriService, useClass: EmailTemplatesUriServiceAngular },
    { provide: BankConsentUriService, useClass: BankConsentUriServiceAngular },
    { provide: VahvistettuKorkoService, useClass: VahvistettuKorkoServiceAngular },
    { provide: ApixUriService, useClass: ApixUriServiceAngular },
    // Shared, Lasku
    { provide: LaskuIndeksoija, useClass: LaskuIndeksoijaAngular },
    { provide: LaskuKopioija, useClass: LaskuKopioijaAngular },
    { provide: LaskuKorkoService, useClass: LaskuKorkoServiceAngular },
    { provide: ReskontraService, useClass: ReskontraServiceAngular },
    { provide: LaskuSharedService, useClass: LaskuSharedServiceAngular },
    { provide: LaskuSpostiService, useClass: LaskuSpostiServiceAngular },
    { provide: LaskuUriService, useClass: LaskuUriServiceAngular },
    { provide: SahkoisenLaskunValittajaService, useClass: SahkoisenLaskunValittajaServiceAngular },
    { provide: LaskuLahetystietojenLuoja, useClass: LaskuLahetystietojenLuojaAngular },
    // Shared in Angular, Lasku
    KonfiguraatioPalvelu,
    AlvNumeronTarkistajaService,
    LaskunAsiakasTypeahead,
    LaskunTuoteTypeahead,
    LaskuService,
    LaskutUusiDataSourceService,
    MaksumuistutusDataSourceService,
    LaskutSelaaDataSourceService,
    LaskutPiilotetutDataSourceService,
    LaskuSummatService,
    // Shared, Kuitti
    { provide: TositeUriService, useClass: TositeUriServiceAngular },
    { provide: TositeIndeksoija, useClass: TositeIndeksoijaAngular },
    { provide: TositeKopioija, useClass: TositeKopioijaAngular },
    { provide: VeroilmoitusLaskentaService, useClass: VeroilmoitusLaskentaServiceAngular },
    TositeService,
    TositeKuvaService,
    TositeSelailuListausFirebaseDataSource,
    // Kirjanpitäjät
    KirjanpitajaService,
    KirjanpitajaKopioija,
    KirjanpitajanAsiakkaidenLaskutDataSourceService,
    { provide: KirjanpitajanTiimiService, useClass: KirjanpitajanTiimiServiceAngular },
    // Asiakkaat
    AsiakasService,
    { provide: AsiakasUriService, useClass: AsiakasUriServiceAngular },
    { provide: AsiakasJaettuService, useClass: AsiakasJaettuServiceAngular },
    { provide: AsiakasJaettuLemonaidService, useClass: AsiakasJaettuLemonaidServiceAngular },
    AsiakasKopioija,
    KayttajaKopioija,
    AsiakkaatDataSourceStateService,
    AsiakkaanKayttajatDataSourceService,
    AsiakasTyojonoLoadingService,
    TilitapahtumatUusiDataSourceService,
    LaskuttavaAsiakasLemonatorProvider,
    // Kirjanpito
    KirjanpitoService,
    KirjanpitoImageService,
    KirjanpitoImageHandlerService,
    KirjanpitoTositeService,
    KirjanpitoRaportitVertailuTiedotService,
    KirjanpitoKuukausiStripService,
    KirjanpitoRaportitAutosaveService,
    KirjanpitoNavigationService,
    { provide: KirjanpitoUriService, useClass: KirjanpitoUriServiceAngular },
    { provide: KirjanpitoJaettuService, useClass: KirjanpitoJaettuServiceAngular },
    { provide: VeroilmoitusTranslationService, useClass: VeroilmoitusTranslationServiceAngular },
    { provide: KirjanpitoReskontraUriService, useClass: KirjanpitoReskontraUriServiceAngular },
    // Asetukset
    { provide: KirjanpitajanAllekirjoitusService, useClass: KirjanpitajanAllekirjoitusServiceAngular },
    { provide: CountryLocalizationService, useExisting: MaaService },
    { provide: LaskuttavaAsiakasProvider, useExisting: LaskuttavaAsiakasLemonatorProvider },
    { provide: TimestampProviderBase, useExisting: TimestampService },
    { provide: LaskunAsiakasTypeaheadBase, useExisting: LaskunAsiakasTypeahead },
    { provide: LaskunTuoteTypeaheadBase, useExisting: LaskunTuoteTypeahead },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: DateAdapter, useClass: LemonDateAdapter },
    { provide: LemonDateAdapter, useClass: LemonDateAdapter },
    // { provide: RouteReuseStrategy, useClass: LemonRouteReuseStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler, deps: [MatSnackBar] },
    { provide: MatSortHeaderIntl, useClass: MyMaterialMatSortHeaderIntl },
    { provide: MatStepperIntl, useClass: MyMaterialMatStepperIntl },
    { provide: MatPaginatorIntl, useClass: MyMaterialPagingIntl },
    // Firebase
    FirebaseLemonator,
    FirebaseLemonaid,
    { provide: SharedFirebaseLemonaid, useExisting: FirebaseLemonaid },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule { }
