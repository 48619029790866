import { initializeApp, FirebaseApp, FirebaseOptions } from 'firebase/app'
import { getAuth, signOut, Auth, User, signInWithCustomToken, UserCredential, getIdToken } from 'firebase/auth'

import {
  getFirestore,
  Firestore,
  getDocs,
  query,
  collection,
  WhereFilterOp,
  serverTimestamp,
  CollectionReference,
  deleteDoc,
  updateDoc,
  doc,
  getDoc,
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  collectionGroup,
  Query,
  QueryConstraint,
  where,
  limit,
  limitToLast,
  orderBy,
  OrderByDirection,
  FieldPath,
  setDoc,
  SetOptions,
  WriteBatch,
  writeBatch,
  arrayUnion,
  PartialWithFieldValue,
  UpdateData,
  WithFieldValue,
  deleteField,
  DocumentChange,
  Timestamp,
  startAfter,
  getDocsFromServer,
  increment,
  runTransaction,
  Transaction,
  getCountFromServer,
  endAt
} from 'firebase/firestore'
import { collection as rxFireCollection, doc as rxFireDoc, collectionChanges as rxFireCollectionChanges } from 'rxfire/firestore'

import { getDatabase, Database, ref as databaseRef, DatabaseReference, DataSnapshot, push, onValue } from 'firebase/database'
import { list, ListenEvent, objectVal } from 'rxfire/database'

import { getStorage, StorageReference, ref, FirebaseStorage, uploadBytesResumable, UploadMetadata, UploadTask } from 'firebase/storage'
import { getFunctions, Functions, HttpsCallableOptions, httpsCallable } from 'firebase/functions'
import { map, Observable } from 'rxjs'

import { Timestamp as LemonTimestamp } from '../_shared-core/model/common'

import { AppCheck, getToken, initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { FirestoreProvider } from 'app/_jaettu/service/lasku/lasku-tallennus.service'

export interface IFirestoreDocQuery<T> {
  location: string
  listen(): Observable<T>
  listenSnap(): Observable<DocumentSnapshot<T>>
  get(): Promise<T>
  getSnap(): Promise<DocumentSnapshot<T>>
}

class FirestoreDocQuery<T> implements IFirestoreDocQuery<T> {
  constructor(path: string, firestore: Firestore) {
    this.location = path
    this._firestore = firestore
  }
  location: string
  private _firestore: Firestore
  get(): Promise<T> {
    return this.getSnap().then(snap => snap.data())
  }
  getSnap(): Promise<DocumentSnapshot<T>> {
    const d = doc(this._firestore, this.location)
    return getDoc(d as DocumentReference<T>)
  }
  listen(): Observable<T> {
    return this.listenSnap().pipe(
      map(snap => snap.data())
    )
  }
  listenSnap(): Observable<DocumentSnapshot<T>> {
    const d = doc(this._firestore, this.location)
    return rxFireDoc<T>(d as DocumentReference<T>)
  }
}

export interface IFirestoreCollectionQuery<T> {

  location: string

  where(fieldPath: keyof T, opStr: WhereFilterOp, value: unknown): IFirestoreCollectionQuery<T>
  whereFree(fieldPath: string | FieldPath, opStr: WhereFilterOp, value: unknown): IFirestoreCollectionQuery<T>
  limit(n: number): IFirestoreCollectionQuery<T>
  limitToLast(n: number): IFirestoreCollectionQuery<T>
  orderBy(fieldPath: keyof T, directionStr: OrderByDirection): IFirestoreCollectionQuery<T>
  orderByFree(fieldPath: string | FieldPath, directionStr: OrderByDirection): IFirestoreCollectionQuery<T>
  startAfter(after: DocumentSnapshot<T> | LemonTimestamp): IFirestoreCollectionQuery<T>
  endAt(at: DocumentSnapshot<T> | LemonTimestamp): IFirestoreCollectionQuery<T>

  getNativeQuery(): Query<T>
  listen(): Observable<T[]>
  // listenCount(): Observable<number>
  listenSnapshots(): Observable<QueryDocumentSnapshot<T>[]>
  listenChanges(): Observable<DocumentChange<T>[]>
  get(): Promise<T[]>
  getCount(): Promise<number>
  /** Bypass cache */
  getFromServer(): Promise<T[]>
  getSnaps(): Promise<QueryDocumentSnapshot<T>[]>

}

class FirestoreCollectionQuery<T> implements IFirestoreCollectionQuery<T> {
  constructor(path: string, firestore: Firestore) {
    this.location = path
    this._firestore = firestore
  }
  location: string
  private _firestore: Firestore
  private _wheres: QueryConstraint[] = []
  _copy(): FirestoreCollectionQuery<T> {
    const copy = new FirestoreCollectionQuery<T>(this.location, this._firestore)
    copy._wheres = [...this._wheres]
    return copy
  }
  where(fieldPath: keyof T, opStr: WhereFilterOp, value: unknown): IFirestoreCollectionQuery<T> {
    this._wheres.push(where(fieldPath as string, opStr, value))
    return this
  }
  whereFree(fieldPath: string | FieldPath, opStr: WhereFilterOp, value: unknown): IFirestoreCollectionQuery<T> {
    this._wheres.push(where(fieldPath, opStr, value))
    return this
  }
  limit(n: number): IFirestoreCollectionQuery<T> {
    this._wheres.push(limit(n))
    return this
  }
  limitToLast(n: number): IFirestoreCollectionQuery<T> {
    this._wheres.push(limitToLast(n))
    return this
  }
  orderBy(fieldPath: keyof T, directionStr: OrderByDirection): IFirestoreCollectionQuery<T> {
    this._wheres.push(orderBy(fieldPath as string, directionStr))
    return this
  }
  orderByFree(fieldPath: string | FieldPath, directionStr: OrderByDirection): IFirestoreCollectionQuery<T> {
    this._wheres.push(orderBy(fieldPath, directionStr))
    return this
  }
  startAfter(after: DocumentSnapshot<T> | LemonTimestamp): IFirestoreCollectionQuery<T> {
    this._wheres.push(startAfter(after))
    return this
  }
  endAt(at: DocumentSnapshot<T> | LemonTimestamp): IFirestoreCollectionQuery<T> {
    this._wheres.push(endAt(at))
    return this
  }
  /**
  * A `QueryConstraint` is used to narrow the set of documents returned by a
  * Firestore query. `QueryConstraint`s are created by invoking  {@link (startAt:1)}, {@link (startAfter:1)}, {@link endBefore:1}, {@link (endAt:1)} and
  */
  get(): Promise<T[]> {
    return this.getSnaps().then(qsnaps => qsnaps.map(d => d.data()))
  }
  getCount(): Promise<number> {
    return getCountFromServer(this.getNativeQuery()).then(snap => snap.data().count)
  }
  /** Bypass cache */
  getFromServer(): Promise<T[]> {
    return this.getSnapsFromServer().then(qsnaps => qsnaps.map(d => d.data()))
  }
  getNativeQuery(): Query<T, T> {
    const coll = collection(this._firestore, this.location)
    return query<T, T>(coll as CollectionReference<T, T>, ...this._wheres)
  }
  getSnaps(): Promise<QueryDocumentSnapshot<T>[]> {
    return getDocs<T, T>(this.getNativeQuery()).then(qsnap => qsnap.docs)
  }
  getSnapsFromServer(): Promise<QueryDocumentSnapshot<T, T>[]> {
    return getDocsFromServer<T, T>(this.getNativeQuery()).then(qsnap => qsnap.docs)
  }

  listen(): Observable<T[]> {
    return this.listenSnapshots().pipe(
      map(querySnapshot => querySnapshot.map(a => a.data()))
    )
  }
  // listenCount(): Observable<number> {
  //   return concat(this.getCount(), interval(10000).pipe(
  //     switchMap(() => this.getCount())
  //   ))
  // }
  listenSnapshots(): Observable<QueryDocumentSnapshot<T>[]> {
    return rxFireCollection<T>(this.getNativeQuery())
  }
  listenChanges(): Observable<DocumentChange<T>[]> {
    return rxFireCollectionChanges<T>(this.getNativeQuery())
  }
}

export interface IFirestoreCollectionGroupQuery<T> {

  location: string

  where(fieldPath: keyof T, opStr: WhereFilterOp, value: unknown): IFirestoreCollectionGroupQuery<T>
  whereFree(fieldPath: string | FieldPath, opStr: WhereFilterOp, value: unknown): IFirestoreCollectionGroupQuery<T>
  limit(n: number): IFirestoreCollectionGroupQuery<T>
  limitToLast(n: number): IFirestoreCollectionGroupQuery<T>
  orderBy(fieldPath: keyof T, directionStr: OrderByDirection): IFirestoreCollectionGroupQuery<T>
  orderByFree(fieldPath: string | FieldPath, directionStr: OrderByDirection): IFirestoreCollectionGroupQuery<T>

  getNativeQuery(): Query<T>
  listen(): Observable<T[]>
  // listenCount(): Observable<number>
  listenSnapshots(): Observable<QueryDocumentSnapshot<T>[]>
  listenChanges(): Observable<DocumentChange<T>[]>
  get(): Promise<T[]>
  getCount(): Promise<number>
  getSnaps(): Promise<QueryDocumentSnapshot<T>[]>

}

class FirestoreCollectionGroupQuery<T> implements IFirestoreCollectionGroupQuery<T> {
  constructor(path: string, firestore: Firestore) {
    this.location = path
    this._firestore = firestore
  }
  location: string
  private _firestore: Firestore
  private _wheres: QueryConstraint[] = []
  _copy(): FirestoreCollectionGroupQuery<T> {
    const copy = new FirestoreCollectionGroupQuery<T>(this.location, this._firestore)
    copy._wheres = [...this._wheres]
    return copy
  }
  where(fieldPath: keyof T, opStr: WhereFilterOp, value: unknown): IFirestoreCollectionGroupQuery<T> {
    this._wheres.push(where(fieldPath as string, opStr, value))
    return this
  }
  whereFree(fieldPath: string | FieldPath, opStr: WhereFilterOp, value: unknown): IFirestoreCollectionGroupQuery<T> {
    this._wheres.push(where(fieldPath, opStr, value))
    return this
  }
  limit(n: number): IFirestoreCollectionGroupQuery<T> {
    this._wheres.push(limit(n))
    return this
  }
  limitToLast(n: number): IFirestoreCollectionGroupQuery<T> {
    this._wheres.push(limitToLast(n))
    return this
  }
  orderBy(fieldPath: keyof T, directionStr: OrderByDirection): IFirestoreCollectionGroupQuery<T> {
    this._wheres.push(orderBy(fieldPath as string, directionStr))
    return this
  }
  orderByFree(fieldPath: string | FieldPath, directionStr: OrderByDirection): IFirestoreCollectionGroupQuery<T> {
    this._wheres.push(orderBy(fieldPath, directionStr))
    return this
  }
  getNativeQuery(): Query<T, T> {
    const coll = collectionGroup(this._firestore, this.location)
    return query<T, T>(coll as Query<T, T>, ...this._wheres)
  }
  get(): Promise<T[]> {
    return this.getSnaps().then(qsnaps => qsnaps.map(d => d.data()))
  }
  getCount(): Promise<number> {
    return getCountFromServer(this.getNativeQuery()).then(snap => snap.data().count)
  }
  getSnaps(): Promise<QueryDocumentSnapshot<T, T>[]> {
    return getDocs<T, T>(this.getNativeQuery()).then(qsnap => qsnap.docs)
  }
  listen(): Observable<T[]> {
    return this.listenSnapshots().pipe(
      map(querySnapshot => querySnapshot.map(a => a.data()))
    )
  }
  // listenCount(): Observable<number> {
  //   return concat(this.getCount(), interval(10000).pipe(
  //     switchMap(() => this.getCount())
  //   ))
  // }
  listenSnapshots(): Observable<QueryDocumentSnapshot<T, T>[]> {
    return rxFireCollection<T>(this.getNativeQuery()) as Observable<QueryDocumentSnapshot<T, T>[]>
  }
  listenChanges(): Observable<DocumentChange<T, T>[]> {
    return rxFireCollectionChanges<T>(this.getNativeQuery()) as Observable<DocumentChange<T, T>[]>
  }
}

export interface IFirestoreBatch {
  set<T>(uri: string, data: WithFieldValue<T>): FirestoreBatch
  set<T>(uri: string, data: PartialWithFieldValue<T>, options: SetOptions): FirestoreBatch
  update<T>(uri: string, data: UpdateData<T>): FirestoreBatch
  updateRef<T>(ref: DocumentReference<T, T>, data: UpdateData<T>): FirestoreBatch
  delete(uri: string): FirestoreBatch
  commit(): Promise<void>
}

class FirestoreBatch implements IFirestoreBatch {
  private _batch: WriteBatch
  constructor(private _firestore: Firestore) {
    this._batch = writeBatch(this._firestore)
  }
  set<T>(uri: string, data: WithFieldValue<T>): FirestoreBatch
  set<T>(uri: string, data: PartialWithFieldValue<T>, options: SetOptions): FirestoreBatch
  set<T>(uri: string, data: PartialWithFieldValue<T> | WithFieldValue<T>, options?: SetOptions): FirestoreBatch {
    const document = doc(this._firestore, uri) as DocumentReference<T, T>
    if (options) {
      this._batch.set<T, T>(document, data as PartialWithFieldValue<T>, options)
    } else {
      this._batch.set<T, T>(document, data as WithFieldValue<T>)
    }
    return this
  }
  update<T>(uri: string, data: UpdateData<T>): FirestoreBatch {
    const document = doc(this._firestore, uri) as DocumentReference<T, T>
    this._batch.update<T, T>(document, data)
    return this
  }
  // eslint-disable-next-line @typescript-eslint/no-shadow
  updateRef<T>(ref: DocumentReference<T, T>, data: UpdateData<T>): FirestoreBatch {
    this._batch.update<T, T>(ref, data)
    return this
  }
  delete(uri: string): FirestoreBatch {
    const document = doc(this._firestore, uri) as DocumentReference<unknown>
    this._batch.delete(document)
    return this
  }
  commit(): Promise<void> {
    return this._batch.commit()
  }
}

export type LemonaidFirebaseConfig = FirebaseOptions & { functionsRegion: string }

export abstract class BaseFirebaseService implements StorageUploader {

  app: FirebaseApp
  appCheck: AppCheck

  private _auth: Auth = null
  get auth(): Auth {
    if (!this._auth) {
      this._auth = getAuth(this.app)
    }
    return this._auth
  }

  private _firestore: Firestore = null
  get firestore(): Firestore {
    if (!this._firestore) {
      this._firestore = getFirestore(this.app)
    }
    return this._firestore
  }

  private _firestoreProvider: FirestoreProvider = null
  get firestoreProvider(): FirestoreProvider {
    if (!this._firestoreProvider) {
      this._firestoreProvider = {
        annaDeleteArvo: () => { return this.firestoreDeleteMarker() },
        annaUusiAvain: () => { return this.firestoreCreateId() },
        // THIS IS REALLY COUNTERINTUITIVE, BUT THE DOC TYPE CONTAINS NO DATA
        // AND THESE DAYS OUR FRONT END FIREBASE INTEGRATION LIBRARY EXPECTS THE URI,
        // NOT THE DOC TO BE PRESENT!
        annaDoc: (uri: string) => { return uri },
        annaUusiBatch: () => { return this.firestoreBatch() }
      }
    }
    return this._firestoreProvider
  }

  private _database: Database = null
  get database(): Database {
    if (!this._database) {
      this._database = getDatabase(this.app)
    }
    return this._database
  }

  private _functions: Functions = null
  get functions(): Functions {
    if (!this._functions) {
      this._functions = getFunctions(this.app, this._options.functionsRegion)
    }
    return this._functions
  }

  constructor(private _options: LemonaidFirebaseConfig, appName: string, recaptchaId: string) {
    this.app = initializeApp(_options, appName)
    // Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
    // site key and pass it to initializeAppCheck().
    if (recaptchaId) {
      this.appCheck = initializeAppCheck(this.app, {
        provider: new ReCaptchaEnterpriseProvider(recaptchaId),
        isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
      })
    }
  }

  databaseRef(path: string): DatabaseReference {
    return databaseRef(this.database, path)
  }

  databaseGet(path: string): Promise<DataSnapshot> {
    const databaseReference = this.databaseRef(path)
    return new Promise((resolve, reject) => {
      onValue(databaseReference, snap => resolve(snap), error => reject(error))
    })
  }

  databaseGetValue<T>(path: string): Promise<T> {
    return this.databaseGet(path).then(snap => snap.val() as T)
  }

  databaseListenValue<T>(path: string, options?: { keyField?: string }): Observable<T> {
    return objectVal<T>(this.databaseRef(path), options)
  }

  databaseListenValueList<T>(path: string, options?: { events?: ListenEvent[] }): Observable<T[]> {
    return list(this.databaseRef(path), options).pipe(map(queryChanges => queryChanges.map(queryChange => queryChange.snapshot.val())))
  }

  databaseCreatePushId(): string {
    return push(databaseRef(this.database)).key
  }

  functionsCall<RequestData = unknown, ResponseData = unknown>(name: string, payload: RequestData, options?: HttpsCallableOptions): Promise<ResponseData> {
    const opts: HttpsCallableOptions = options ? options : { timeout: 540 * 1000 }
    return httpsCallable<RequestData, ResponseData>(this.functions, name, opts)(payload).then(res => res.data)
  }

  authUserObservable = new Observable<User | null>(sub => {
    this.auth.onAuthStateChanged(
      next => sub.next(next),
      err => sub.error(err),
      () => sub.complete()
    )
  })

  authSignOut(): Promise<void> {
    return signOut(this._auth)
  }

  authGetIdToken(user: User, forceRefresh?: boolean): Promise<string> {
    return getIdToken(user, forceRefresh)
  }

  async authSignInWithCustomToken(token: string): Promise<UserCredential> {
    await this.appCheckGetToken(true)
    return signInWithCustomToken(this._auth, token)
  }

  appCheckGetToken(forceRefresh: boolean = false): Promise<string> {
    if (this.appCheck) {
      return getToken(this.appCheck, /* forceRefresh= */ forceRefresh).then(result => result?.token)
    }
  }

  firestoreServerTimestamp(): Timestamp {
    return serverTimestamp() as any as Timestamp
  }

  firestoreTimestampFromDate(date: Date): Timestamp | null {
    if (date) {
      return Timestamp.fromDate(date)
    }
    return null
  }

  firestoreTimestampNew(seconds: number, nanos: number): Timestamp | null {
    return new Timestamp(seconds, nanos)
  }

  firestoreIsTimestamp(obj: any): boolean {
    return obj instanceof Timestamp
  }

  firestoreDeleteMarker(): any {
    return deleteField() as any
  }

  firestoreSetData<T>(path: string, data: T, options?: SetOptions): Promise<void> {
    return setDoc<T, T>(this.firestoreDocRef<T>(path), data, options)
  }

  firestoreUpdateData<T>(path: string, data: T): Promise<void> {
    return updateDoc<T, T>(this.firestoreDocRef<T>(path), data as UpdateData<T>)
  }

  firestoreDeleteDoc(path: string): Promise<void> {
    const d = doc(this.firestore, path)
    return deleteDoc(d)
  }

  firestoreFieldPath(...fieldNames: string[]): FieldPath {
    return new FieldPath(...fieldNames)
  }

  firestoreFieldIncrement(n: number): any {
    return increment(n)
  }

  firestoreUpdateFields<T>(path: string, ...updatedFields: { data: T, fieldPath: FieldPath }[]): Promise<void> {
    if (!updatedFields || updatedFields.length < 1) {
      throw new Error('No update fields specified')
    }

    const first = updatedFields[0]
    if (updatedFields.length === 1) {
      return updateDoc(this.firestoreDocRef<T>(path), first.fieldPath, first.data)
    }

    const copy = [...updatedFields]
    copy.shift() // Poista ensimmäinen
    const rest: (FieldPath | T)[] = []
    for (const entry of copy) {
      rest.push(entry.fieldPath)
      rest.push(entry.data)
    }
    return updateDoc(this.firestoreDocRef<T>(path), first.fieldPath, first.data, ...rest)
  }

  firestoreDocRef<T>(path: string): DocumentReference<T, T> {
    return doc(this.firestore, path) as DocumentReference<T, T>
  }

  firestoreDoc<T>(path: string): IFirestoreDocQuery<T> {
    return new FirestoreDocQuery<T>(path, this.firestore)
  }

  firestoreCollection<T>(path: string): IFirestoreCollectionQuery<T> {
    return new FirestoreCollectionQuery<T>(path, this.firestore)
  }

  firestoreCollectionGroup<T>(group: string): IFirestoreCollectionGroupQuery<T> {
    return new FirestoreCollectionGroupQuery<T>(group, this.firestore)
  }

  firestoreArrayUnion(...elements: unknown[]) {
    return arrayUnion(...elements)
  }

  firestoreBatch(): IFirestoreBatch {
    return new FirestoreBatch(this.firestore)
  }

  firestoreCreateId(): string {
    return doc(collection(this._firestore, '_')).id
  }

  firestoreRunTransaction<T>(updateFunction: (transaction: Transaction) => Promise<T>): Promise<T> {
    return runTransaction<T>(this.firestore, updateFunction)
  }

  storage(bucket?: string): FirebaseStorage {
    return getStorage(this.app, bucket)
  }

  storageFile(uri: string, bucket?: string): StorageReference {
    const storage = this.storage(bucket)
    return ref(storage, uri)
  }

  storageUpload(uri: string, data: Blob | Uint8Array | ArrayBuffer, metadata?: UploadMetadata, bucket?: string): UploadTask {
    const fileReference = this.storageFile(uri, bucket)
    return uploadBytesResumable(fileReference, data, metadata)
  }

}

export interface StorageUploader {
  storageUpload(uri: string, data: Blob | Uint8Array | ArrayBuffer, metadata?: UploadMetadata, bucket?: string): UploadTask
}

export abstract class SharedFirebaseLemonaid extends BaseFirebaseService { }
