import { Timestamp, LocalDate, LocalDateTime, LocalMonth, ErrorResponse, BaseEntity, NumberDate, NumberMoney, BaseHistoryEntity, NumberTimestamp, NumberMonth } from '../../_shared-core/model/common'
import { FirestoreTosite } from '../../_jaettu/model/tosite'
import { Lasku, EmailLahetysStatus } from '../../_jaettu/model/lasku'
import { TuettuKieli } from '../../_shared-core/model/common'
import { AlvIlmoitusjakso, Tilikausi } from './asiakas'
import { IlmoitusRivi } from '../service/base-ilmoitin-export.service'
import { RaporttiType } from '../../_jaettu/model/reports-shared'
import { AsiakkaanMaksutapa, CustomerOsakas } from '../../_jaettu/model/kayttaja'
import { Tilitapahtuma } from '../../_jaettu/model/tiliote'

export type MonikielinenTeksti = { [kieli in TuettuKieli]: string }

// asiakkaat/{asiakasavain}/muut/tilikartta
export interface AsiakkaanTilikartta {
  tilit: { [tilinumero: string]: Kirjanpitotili }
  yliajotilit: { [tilinumero: string]: Kirjanpitotili }
  profiili?: PaatilikartanProfiili['avain']
}

// admin/paatilikartta
export interface Paatilikartta {
  tilit: { [tilinumero: string]: Kirjanpitotili }
  profiilit: { [avain in PaatilikartanProfiili['avain']]: PaatilikartanProfiili }
}

export interface PaatilikartanProfiilinOsa {
  nimi?: MonikielinenTeksti
  piilota?: 1
}

export interface PaatilikartanProfiili {
  avain: 'maksuperusteinen' | 'liikekirjuri'
  nimi: string
  t: { [numero: string]: PaatilikartanProfiilinOsa }
}

export interface Kirjanpitotili {
  vanhempi: string
  numero: string
  nimi: string
  oletusAlvKasittely: AlvMaaritys
  aktiivinen: boolean
  alvTyyppi: KirjanpitotilinAlvTyyppi
  localisedName?: MonikielinenTeksti
  reskontraActive?: boolean
  piilotettuProfiilissa?: true
  poistotiedot?: KirjanpitotilinPoistotiedot
}

export interface KirjanpitotilinPoistotiedot {
  poistoprosentti: number
  poistotili: string
}

export enum KirjanpitotilinAlvTyyppi {
  MYYNTI = 1,
  OSTO = 2,
  EI_ALV_KASITTELYA = 3,
  /**
   * OSS Myynti
   */
  ETAMYYNTI = 4,
  /**
   * OSS myynti alle 10k.
   */
  ETAMYYNTI_EI_REKISTEROITYNYT = 5
}

export interface LokalisoituKirjanpitotilinAlvTyyppi {
  nimi: string
  tyyppi: KirjanpitotilinAlvTyyppi
}
export class LokalisoidutAlvtyypit {
  public static readonly EI_ALV_KASITTELYA: LokalisoituKirjanpitotilinAlvTyyppi = { nimi: 'Ei ALV-käsittelyä', tyyppi: KirjanpitotilinAlvTyyppi.EI_ALV_KASITTELYA }
  public static readonly MYYNTI: LokalisoituKirjanpitotilinAlvTyyppi = { nimi: 'Myynti', tyyppi: KirjanpitotilinAlvTyyppi.MYYNTI }
  public static readonly OSTO: LokalisoituKirjanpitotilinAlvTyyppi = { nimi: 'Osto', tyyppi: KirjanpitotilinAlvTyyppi.OSTO }
  public static readonly ETAMYYNTI: LokalisoituKirjanpitotilinAlvTyyppi = { nimi: 'Etämyynti', tyyppi: KirjanpitotilinAlvTyyppi.ETAMYYNTI }
  public static readonly ETAMYYNTI_EI_REKISTEROITYNYT: LokalisoituKirjanpitotilinAlvTyyppi = { nimi: 'Etämyynti alle 10k', tyyppi: KirjanpitotilinAlvTyyppi.ETAMYYNTI_EI_REKISTEROITYNYT }
  public static kaikki: LokalisoituKirjanpitotilinAlvTyyppi[] = [
    LokalisoidutAlvtyypit.EI_ALV_KASITTELYA,
    LokalisoidutAlvtyypit.MYYNTI,
    LokalisoidutAlvtyypit.OSTO,
    LokalisoidutAlvtyypit.ETAMYYNTI,
    LokalisoidutAlvtyypit.ETAMYYNTI_EI_REKISTEROITYNYT
  ]
}

export enum KirjauksienLuontityyppi {

  /**
   * Kirjaukset generoidaan sähköisestä tiliotteesta (Perinteinen tili)
   */
  SAHKOINEN_TILIOTE = 's',

  /**
   * Kirjaukset generoidaan sähköisestä tiliotteesta (maksukortti)
   */
  MAKSUKORTIN_SAHKOINEN_TILIOTE = 'm',

  /**
   * Kirjaukset generoidaan maksutapahtumaan merkityistä tositteista
   */
  TOSITTEISTA_GENEROITAVA_TILIOTE = 'g',

  /**
   * Kirjauksia ei generoida, vaan ne muodostuvat kirjanpitäjän toimesta ja
   * tositteet kiinnitetään summan mukaan / käsin
   */
  KASIN_SYOTETTAVA_TILIOTE = 'k',

  /**
   * Kirjaukset generoidaan jos osana integraatiopipelineä, esim. Holvi.
   * Tästä syystä niitä ei tarvitse enää myöhemmin luoda tositteiden Lemonator tuonnin yhteydessä
   */
  HOLVI_SAHKOINEN_TILIOTE = 'o',

  /**
   * Tositteesta ei tuoda mitään versiota kirjanpidon puolelle.
   */
  EI_TUODA_LAINKAAN_KIRJANPITOON = 'e'

}

// // asiakkaat/{asiakasAvain}/kirjattavat-laskut-new/{laskunAvain}
// export interface KirjattavaLaskuNew {
//   /**
//    * Laskun summa
//    */
//   s: number
//   /**
//    * Laskun kuvaus
//    */
//   k: string
//   /**
//    * Laskun päivämäärä yyyykkpp
//    */
//   p: number
//   /**
//    * Onko kirjanpitäjän poistama?
//    */
//   d: boolean
//   /**
//    * Itse lasku
//    */
//   i: Lasku
//   /**
//    * Liitokset: missä kirjauksissa lasku on?
//    */
//   l?: { [kirjauksenAvain: string]: boolean }
// }

// // asiakkaat/{asiakasAvain}/kirjattavat-kuitit-new/{kuitinAvain}
// export interface KirjattavaKuittiNew {
//   /**
//    * Tositteen summa
//    */
//   s: number
//   /**
//    * Tositteen kuvaus
//    */
//   k: string
//   /**
//    * Tositteen päivämäärä yyyykkpp
//    */
//   p: number
//   /**
//    * Onko kirjanpitäjän poistama?
//    */
//   d: boolean
//   /**
//    * Kuvat
//    */
//   i: {
//     a: string // Kuvan avain
//     o: number // Järjestys
//     t: ImageType // Kuvan tyyppi
//   }[]
//   /**
//    * Liitokset: missä kirjauksissa kuitti on?
//    */
//   l?: { [kirjauksenAvain: string]: boolean }
// }

export enum KirjaukseenLiitetynTiedostonSivunTyyppi {
  /**
   * Kirjanpitäjän raahaama tiedosto Webp-muodossa.
   */
  RAAHATTU_WEBP = 'r',
  /**
   * Kirjanpitäjän raahaama tiedosto PDF-muodossa.
   */
  RAAHATTU_PDF = 'a',
  /**
   * Kirjanpitäjän raahaama tiedosto PDF-muodossa.
   */
  RAAHATTU_PDF_RAJAYTETTY_SIVUIKSI = 'c',
  /**
   * Lasku. Binääri suoraan payloadissa.
   */
  LASKU = 'l',
  /**
   * Kuitin alkuperäinen, käsittelemätön kuva, joka on PDF-muodossa
   */
  KUITTI_ALKUPERAINEN_PDF = 'p',
  /**
   * Kuitin alkuperäinen, käsittelemätön kuva, joka on PDF-muodossa
   */
  KUITTI_ALKUPERAINEN_PDF_RAJAYTETTY_JPEG_SIVUIKSI = 'b',
  /**
   * Kuitin alkuperäinen, muu kuin PDF.
   * Toistaiseksi jokin tuetuista kuva formaateista.
   */
  KUITTI_ALKUPERAINEN_MUU = 'k',
  /**
   * Webp-muodossa tallennettu kuitin kuva.
   */
  // KUITTI_WEBP = 'w',
  /**
   * Jpeg-muodossa tallennettu kuitin kuva.
   */
  KUITTI_JPEG = 'j',
  /**
   * Kuvaton kuitti
   */
  KUITTI_NO_PAGES = 'n'
}

// export type AttachedDocumentType = 'receipt' | 'invoice' | 'dragged'
// export type AttachedDocumentSource = 'lemonaid-receipt' | 'lemonaid-invoice' | 'holvi' | 'holviapi' | 'accountant'
// export interface AttachedDocument {

//   key: string
//   type: AttachedDocumentType
//   source: AttachedDocumentSource
//   order: number
//   pages?: AttachedDocumentPage[]

//   // Only if receipt
//   receiptText?: string
//   receiptKey?: string
//   receiptImageFolder?: string

//   // Only if Lasku
//   invoice?: Lasku

//   /** Is the document hidden from UI? This is used to archive some documents, but not show them to accountants by default. */
//   hidden?: 1

// }

// export interface AttachedDocumentPage {

//   /** The Key of Lasku, FirestoreTosite or Raahattu */
//   key: string
//   type: KirjaukseenLiitetynTiedostonSivunTyyppi
//   order: number

//   // Only if user has rotated/zoomed in UI and the values have been persisted
//   zoomMultiplier?: number
//   rotationDegrees?: '0' | '90' | '180' | '270'

//   // Only if from Holvi ZIP
//   holviFilename?: string

//   // Only if original file
//   originalFileEnding?: string
//   originalImageFolder?: string
//   /** Original PDF, that has been pre-rendered as images, the image keys in correct order. */
//   originalPdfImageKeys?: string[]

//   // Only if dragged
//   /** Dragged file PDF, that has been pre-rendered as images, the image keys in correct order. */
//   draggedPdfImageKeys?: string[]

// }

// export type ImageType = /** jpeg */ 'j' | /** webp */ 'w' | /** pdf */ 'p'
// export type KirjauksenTositetyyppi = /** kuva */ 'k' | /** alkuperäinen */ 'a' | /** lasku */ 'l' | /** raahatttu */ 'r'
export interface KirjaukseenLiitetynTiedostonSivu {
  /**
   * Kuitilla kuitin avain
   */
  a?: string
  /**
   * Kuitilla kuvakansio. Alkuperäisellä alkuperäisen kuvakansio.
   */
  k?: string
  /**
   * Alkuperäisellä file ending
   */
  f?: string
  /**
   * Alkuperäisellä PDF:llä kuvien kuvakansio
   */
  y?: string
  /**
   * Alkuperäisellä PDF:llä, joka on räjäytetty JPEG kuviksi, kuvien avaimet järjestyksessä.
   */
  c?: string[]
  /**
   * Alkuperäisellä RAAHATULLA PDF:llä, joka on räjäytetty JPEG kuviksi, kuvien avaimet järjestyksessä.
   */
  d?: string[]
  /**
   * Laskulla itse lasku
   */
  l?: Lasku
  /**
   * Holvista tulleilla tiedostoilla tiedoston nimi
   */
  b?: string
  /**
   * Kuvan tyyppi
   */
  t: KirjaukseenLiitetynTiedostonSivunTyyppi
  /**
   * Järjestys, lisäyshetken millisekunnit
   */
  o: number
  /**
   * Hidden from UI?
   */
  h?: 1
}

export interface KirjaukseenLiitettyjenTiedostojenSivutHistoriassa {

  /**
   * Kuvat tallentaneen kirjanpitäjän avain
   */
  k: string

  /**
   * Päivitetty aikaleima, linux epoch millis
   */
  u: number

}

export interface KirjaukseenLiitettyjenTiedostojenSivut {

  /**
   * Kirjauksen päivämäärä yykkpp
   */
  p: number

  /**
   * Tiedostot
   */
  t: {
    /** Kuitin kuvan, laskun tai raahatun tiedoston avain */
    [avain: string]: KirjaukseenLiitetynTiedostonSivu
  }

}


// export interface KirjauksenTallennuspyyntoUusi {
//   asiakasAvain: string
//   kirjaus: KirjausNew
//   uusiPaivamaara?: Timestamp
//   uusiMaksutapaTunniste?: string
// }
// export interface KirjauksenPoistopyyntoUusi {
//   asiakasAvain: string
//   kirjaus: KirjausNew
// }
// export interface KirjauksenPoistovastausUusi {

// }
// /**
//  * Path in Firestore: asiakkaat/{asiakasavain}/kirjanpito-kirjaukset
//  */
// export interface KirjausNew {

//   /**
//    * Numero, joka yksilöi kirjauksen
//    * MUOTO ON myykk-x
//    * Jossa
//    * p = maksutavan tunniste (payment method)
//    * y = vuosi
//    * m = kuukausi
//    * x = juokseva numero
//    * HUOM! tässä kentässä on VAIN juokseva numero. Kokoa muut tiedot muista kentistä.
//    * 11905-1
//    */
//   n: number

//   /**
//    * Kirjauksen päivämäärä yykkpp
//    */
//   p: number

//   /**
//    * Kirjauksen summa, joko tilitapahtumasta, kirjanpitäjän syöttämänä tai tositteesta.
//    */
//   s: number

//   /**
//    * Kirjauksen luontiaika - linux millis
//    */
//   c: number

//   /**
//    * Kirjauksen tallennusaika - linux millis
//    */
//   u: number

//   /**
//    * Kirjauksen tallentaneen kirjanpitäjän avain
//    */
//   k: string

//   /**
//    * Kirjauksen jakauma tileille
//    */
//   r: KirjausriviNew[]

//   /**
//    * Sen maksutavan tunniste, johon tämä kirjaus on liitetty
//    */
//   m: string

//   /**
//    * Koko kirjausta koskeva kommentti
//    */
//   e?: string

//   /**
//    * Ensimmäinen versio vai ei. False === eka, true on myöhempi.
//    */
//   v: boolean

//   /**
//    * Onko kirjanpitäjä hyväksynyt juuri tämän version
//    */
//   h: boolean

//   /**
//    * Onko kirjaus poistettu
//    */
//   d: boolean

//   /**
//    * Onko kirjaus lukittu
//    */
//   l: boolean


//   /**
//    * Jos lähtöisin tilitapahtumasta ja jos tiedossa, tilin saldo tämän tapahtuman jälkeen.
//    */
//   g?: number
//   /**
//    * Kirjauksen viitenumero tilitapahtumasta jos saatavilla
//    */
//   x?: string
//   /**
//    * Kirjauksen maksaja tai maksunsaaja, jos tilitapahtumasta
//    */
//   a?: string
//   /**
//   * Kirjauksen lisätiedot Lemonaid tositteen viestistä
//   */
//   b?: { [avain: string]: string }
//   /**
//    * Kirjauksen lisätiedot tilitapahtumasta
//    */
//   f?: string

// }

// /**
//  * Path in Firestore: asiakkaat/{asiakasavain}/kirjanpito-kirjaukset-muut-tiedot
//  */
// export interface KirjauksenMuutTiedot {

//   /**
//    * Numero, joka yksilöi kirjauksen
//    * MUOTO ON myykk-x
//    * Jossa
//    * p = maksutavan tunniste (payment method)
//    * y = vuosi
//    * m = kuukausi
//    * x = juokseva numero
//    * HUOM! tässä kentässä on VAIN juokseva numero. Kokoa muut tiedot muista kentistä.
//    * 11905-1
//    */
//   n: number

//   /**
//    * Kirjauksen päivämäärä yykkpp
//    */
//   p: number

//   /**
//    * Kirjauksen summa, joko tilitapahtumasta, kirjanpitäjän syöttämänä tai tositteesta.
//    */
//   s: number

//   /**
//    * Kirjauksen luontiaika - linux millis
//    */
//   c: number

//   /**
//    * Jos lähtöisin tilitapahtumasta ja jos tiedossa, tilin saldo tämän tapahtuman jälkeen.
//    */
//   g?: number
//   /**
//    * Kirjauksen viitenumero tilitapahtumasta jos saatavilla
//    */
//   v?: string
//   /**
//    * Kirjauksen maksaja tai maksunsaaja, jos tilitapahtumasta
//    */
//   m?: string
//   /**
//   * Kirjauksen lisätiedot Lemonaid tositteen viestistä
//   */
//   b?: { [avain: string]: string }
//   /**
//    * Kirjauksen lisätiedot tilitapahtumasta
//    */
//   l?: string

// }

/**
 * Path in Firestore: asiakkaat/{asiakasavain}/kirjanpito-kirjaukset
 */
// export interface KirjausNew {
//   /**
//    * Kirjauksen päivämäärä yykkpp
//    */
//   p: number
//   /**
//    * Kirjauksen luontiaika
//    */
//   kirjausLuotu: Timestamp
//   /**
//    * Kirjauksen tallennusaika
//    */
//   kirjausTallennettu: Timestamp
//   /**
//    * Kirjanpitäjän avain
//    */
//   kirjauksenLuoja: string
//   /**
//    * Kirjauksen jakauma tileille
//    */
//   rivit: Kirjausrivi[]
//   /**
//    * Koko kirjausta koskeva kommentti
//    */
//   kommentti: string
//   /**
//    * Sen maksutavan tunniste, johon tämä kirjaus on liitetty
//    */
//   maksutavanTunniste: string
//   /**
//    * Ensimmäinen versio vai ei. False === eka, true on myöhempi.
//    */
//   versioita: boolean
//   /**
//    * Onko kirjanpitäjä hyväksynyt juuri tämän version
//    */
//   kirjanpitajaHyvaksynyt: boolean
//   /**
//    * Onko kirjaus poistettu
//    */
//   poistettu: boolean
//   /**
//    * Onko kirjaus lukittu
//    */
//   lukittu: boolean
//   /**
//    * Viimeisimmän tallennuksen yksilöivä merkkijono.
//    */
//   tallennustunnus?: string
// }

export enum KirjauksenTyyppi {
  AUTOMAATTIKIRJAUS_EI_MUOKATTAVISSA = 'a',
  AUTOMAATTIKIRJAUS_VAIN_PVM_MUOKATTAVISSA = 'b',
  OSTOVELAN_PURKU = 'c'
}

export interface KirjauksenMuutoksenSyyMuuttunutYhteys {
  poistaKirjanpitajanHyvaksynta: boolean
  avain: string
}

export interface KirjauksenMuutoksenSyy {
  // eslint-disable-next-line max-len
  muutoksenLahde: 'lasku-tagit-muuttuivat' | 'tosite-tagit-muuttuivat' | 'raahattu-tagit-muuttuivat' | 'tilitapahtuma-tagit-muuttuivat' | 'kirjanpitaja-tallensi' | 'kirjanpitaja-kopioi' | 'tilitapahtuma-muuttui' | 'holvi-tapahtuma-muuttui' | 'tosite-muuttui' | 'lasku-muuttui' | 'automaattilaskenta' | 'alustus' | 'alustus-kirjausehdotus' | 'data-korjaus' | 'kirjanpitaja-jaksotti' | 'kirjanpitaja-poisti-jaksotuksen' | 'kirjausrivit-muuttuivat' | 'kirjanpitaja-irroitti-kuitin' | 'kirjanpitaja-liitti-kuitin' | 'kirjanpitaja-liitti-laskun' | 'kirjanpitaja-irroitti-laskun' | 'kirjanpitaja-liitti-raahatun' | 'kirjanpitaja-irroitti-raahatun' | 'kirjanpitaja-poisti-kirjauksen' | 'kirjanpitaja-poisti-raahatun' | 'kirjanpitaja-poisti-kuitin' | 'kirjanpitaja-poisti-laskun' | 'sahkoinen-ostolasku-muuttui' | 'liite-muuttui' | 'kirjanpitaja-muutti-laskuperusteiseksi' | 'kirjanpitaja-jaksotti-kulun-usealle-kuukaudelle' | 'migraatio' | 'automaattisen-poistokirjauksen-paivitys' | 'automaattisen-vuosipoistokirjauksen-paivitys'
  muutoksenTarkemmatTiedot: any
  poistaKirjanpitajanHyvaksynta: boolean
  lisatytYhteydetKirjauksiin?: KirjauksenMuutoksenSyyMuuttunutYhteys[]
  poistetutYhteydetKirjauksiin?: KirjauksenMuutoksenSyyMuuttunutYhteys[]
  lisatytYhteydetTositteisiin?: KirjauksenMuutoksenSyyMuuttunutYhteys[]
  poistetutYhteydetTositteisiin?: KirjauksenMuutoksenSyyMuuttunutYhteys[]
  lisatytYhteydetRaahattuihin?: KirjauksenMuutoksenSyyMuuttunutYhteys[]
  poistetutYhteydetRaahattuihin?: KirjauksenMuutoksenSyyMuuttunutYhteys[]
  lisatytYhteydetLaskuihin?: KirjauksenMuutoksenSyyMuuttunutYhteys[]
  poistetutYhteydetLaskuihin?: KirjauksenMuutoksenSyyMuuttunutYhteys[]
}

/**
 * Path in Firestore: asiakkaat/{asiakasavain}/kirjanpito-kirjaukset
 */
export interface Kirjaus {
  /**
   * Numero, joka yksilöi kirjauksen
   * MUOTO ON pyymm-x
   * Jossa
   * p = maksutavan tunniste (payment method)
   * y = vuosi
   * m = kuukausi
   * x = juokseva numero
   * HUOM! kuukausi PITÄÄ olla etunollitettu, eli esim 5.3.2019 tapahtunut tapahtuma voisi olla
   * 11905-1
   */
  kirjausnumero: string
  /**
   * Primary key
   */
  avain: string
  /**
   * Kirjauksen päivämäärä yykkpp
   */
  p: number
  /**
   * Kirjauksen summa, joko tilitapahtumasta, kirjanpitäjän syöttämänä tai tositteesta.
   */
  kirjauksenSumma: number
  /**
   * Jos lähtöisin tilitapahtumasta ja jos tiedossa, tilin saldo tämän tapahtuman jälkeen.
   */
  tilinSaldo: number
  /**
   * Kirjauksen lisätiedot Lemonaid tositteesta
   */
  kirjauksenLemonaidViesti: string
  /**
   * Additional user messages if this data originated from an API (eg Holvi)
   */
  userApiMessage?: string
  /**
   * Kirjauksen lisätiedot, jos tilitapahtumasta
   */
  kirjauksenViesti: string
  /**
   * Kirjauksen viitenumero, jos tilitapahtumasta tai sähköisestä laskusta
   */
  kirjauksenViitenumero: string
  /**
   * Kirjauksen maksaja tai maksunsaaja, jos tilitapahtumasta tai sähköisestä laskusta
   */
  kirjauksenSaajaTaiMaksaja: string
  /**
   * Kirjauksen maksaja tai maksunsaaja, jos tiedossa
   * Maksun saajan (jos raha ulos) tai maksajan iban (jos raha sisään) jos saatavilla.
   */
  kirjauksenSaajaTaiMaksajaIban: string
  /**
   * Kirjauksen jarjestys, jos tilitapahtumasta
   */
  kirjauksenJarjestysTilitapahtumasta: number
  /**
   * Kirjauksen luontiaika
   */
  kirjausLuotu: Timestamp
  /**
   * Kirjauksen tallennusaika
   */
  kirjausTallennettu: Timestamp
  /**
   * Kirjanpitäjän avain
   */
  kirjauksenLuoja: string
  /**
   * Kirjauksen jakauma tileille
   */
  rivit: Kirjausrivi[]
  /**
   * Koko kirjausta koskeva kommentti
   */
  kommentti: string
  /**
   * Sen maksutavan tunniste, johon tämä kirjaus on liitetty
   */
  maksutavanTunniste: string
  /**
   * Ensimmäinen versio vai ei. False === eka, true on myöhempi.
   */
  versioita: boolean
  /**
   * Onko kirjanpitäjä hyväksynyt juuri tämän version
   */
  kirjanpitajaHyvaksynyt: boolean
  /**
   * Muutoksen syy; miksi kirjauksesta on tehty uusi versio?
   */
  muutoksenSyy?: KirjauksenMuutoksenSyy
  /**
   * Onko kirjaus poistettu
   */
  poistettu: boolean
  /**
   * Onko kirjaus lukittu, koska koko tilikausi on lukittu?
   */
  lukittu: boolean
  // /** Documents that are attached to this kirjaus */
  // attachedDocuments: {
  //   [key: string]: AttachedDocument
  // }
  /**
   * Kirjauksen tyyppi
   */
  t?: KirjauksenTyyppi
  /**
   * Onko yksityisotto
   */
  yksityisotto?: boolean

  /**
   * Kirjaukseen liittyvät jaksotustiedot
   */
  jaksotus?: KirjauksenJaksotustiedot

  /**
   * Kirjaukseen liittyvät poistotiedot
   */
  poistotiedot?: KirjauksenPoistotiedot

  /** Tunniste, jolla samanlaiset kirjaukset voidaan tunnistaa */
  tunniste?: 'omaverotasmaytys_oy_erapaiva' | 'omaverotasmaytys_oy_kk' | 'omaverotasmaytys_tmi_yearly' | 'omaverotasmaytys_tmi_kk' | 'omaverotasmaytys_tmi_erapaiva' | 'suoritep_jaksotus' | 'automaattipoisto_kuluva_tilikausi' | 'automaattipoisto_vuosipoisto'

  /** Kanava, josta kirjaus on tullut */
  kanava: Tilitapahtuma['kanava'] | 'hzip' | 'lasku' | 'tosite' | 'elasku' | 'kasin' | 'omavero' | 'palkkausfi' | 'alvautom' | 'tilikautom' | 'jaksotusautom' | 'poistoautom'

  /**
   * Jos kopioitu, alkuperäisen avain
   * Lisätty 28.1.2025
   */
  alkuperainenKirjaus?: string

}

export interface KirjauksenJaksotustiedot {
  /**
   * Sen kirjauksen avain, joka jaksottaa alkuperäistä
   */
  jaksotus: string
  /**
   * Sen kirjauksen avain, jota tämä jaksottaa
   */
  alkuperainen: string
  /**
   * Sen kirjauksen avain joka vastakirjaa tämän jaksotuksen.
   */
  jaksotuksenVastakirjaus: string
}

export interface KirjauksenPoistotiedot {
  /**
   * Sen kirjauksen avain, josta poisto luotu
   */
  alkuperainen: string
}

export enum RaportointikirjauksenTyyppi {
  OMAVEROTASMAYTYS = 1
}

/**
 * Path in Firestore: asiakkaat/{asiakasavain}/kirjanpito-raporttikirjaukset
 */
export interface Raportointikirjaus {
  /** Kirjausnumero, the same as in {@link Kirjaus#kirjausnumero} */
  k: string
  /** Kirjauksen päivämäärä, format yymmdd */
  p: number
  /** Account numbers */
  a: string[]
  /** Kirjauksen rivit */
  r: Raportointikirjausrivi[]
  /** Maksutavan tunniste */
  t: string
  /** Saaja tai maksaja */
  s: string
  /** Viesti */
  v: string
  /** LemonaidViesti + userApiMessage */
  lv: string
  /** LaskuViesti AKA InvoiceViesti */
  iv: string
  /** Viitenumero */
  vn: string
  /** Onko kirjaus lukittu, koska koko tilikausi on lukittu? */
  l: boolean
  /** Tyyppi */
  rt?: RaportointikirjauksenTyyppi
}
export interface Raportointikirjausrivi {
  /** ALV tunniste */
  a: string
  /** Tilinumero */
  t: string
  /** Debet */
  d: number
  /** Kredit */
  k: number
  /** Kommentti (viesti) */
  v: string
  /** Projekti */
  pr?: string
}
export interface RaportointikirjausriviExtraFields {
  kirjausnumero: string
  pvmString: string
  maksutapa: string
  saajaTaiMaksaja: string
  viesti: string
  lemonaidViesti: string
  laskuViesti: string
  viitenumero: string
}

export interface RaportointikirjausRiviExpanded extends Raportointikirjausrivi, RaportointikirjausriviExtraFields {
}
export interface VirallisetLaskelmatExportRequest {
  asiakasAvain: string
  tilikausiAvain: string
  valittuKieli: TuettuKieli
  laskelmaType: VirallinenRaporttiName
  veroilmoitusAvain?: string
  veroilmoitus?: VeroilmoitusFinal
}

export interface VirallisetLaskelmatExportResponse extends ErrorResponse {
  base64File: string
}

export interface KirjausriviOld {
  /** Tili */
  tili: Kirjanpitotili
  /** Debet */
  debet: number
  /** Kredit */
  kredit: number
  /** Alv */
  alv: AlvMaaritys
  /** Kommentti */
  kommentti: string
}

export interface Kirjausrivi {
  /** Avain */
  av: string
  /** Kirjanpitotili: numero (tunniste) */
  t: string
  /** Debet */
  d: number
  /** Kredit */
  k: number
  /** Alv */
  a: AlvMaaritys['tunniste']
  /** Selite / kommentti */
  s?: string
  /** Projekti */
  pr?: string
  /** Confidence Level of automatic kirjaukset, expressed as number between 0 and 100 */
  c?: number
  /** Reskontran tila */
  r?: 'ok' | 'partial-ok' | 'not-ok'
}

export interface AlvMaaritys {
  tunniste: string
  nimi: string
  lyhenne: string
  kirjaustili: string
  prosentti: number
  kaannetty: boolean
  oletus?: 1
  lopetettu?: number
}

// Osto:

// Osto 0%                    O0
// Osto 10%                   O10
// Osto 14%                   O14
// Osto 24%                   O24
// Osto 100%                  O100

// EU-tavara 10%              OET10
// EU-tavara 14%              OET14
// EU-tavara 24%              OET24
// EU-palvelu 10%             OET10
// EU-palvelu 14%             OET14
// EU-palvelu 24%             OET24

// Rakentamispalvelun osto    ORAK
// Tavaroiden maahantuonti    OTUO
export class OstoAlvt {

  /**
   * NOTE! NOTE! While lyhenne and tunniste can be different, both must be UNIQUE!
   */

  public static readonly OSTO_0: AlvMaaritys = { tunniste: 'o0', kirjaustili: '1763', nimi: 'Osto 0%', lyhenne: 'O0', prosentti: 0, kaannetty: false }
  public static readonly OSTO_10: AlvMaaritys = { tunniste: 'o10', kirjaustili: '1763', nimi: 'Osto 10%', lyhenne: 'O10', prosentti: 10, kaannetty: false }
  public static readonly OSTO_14: AlvMaaritys = { tunniste: 'o14', kirjaustili: '1763', nimi: 'Osto 14%', lyhenne: 'O14', prosentti: 14, kaannetty: false }
  public static readonly OSTO_24: AlvMaaritys = { tunniste: 'o24', kirjaustili: '1763', nimi: 'Osto 24%', lyhenne: 'O24', prosentti: 24, kaannetty: false }
  public static readonly OSTO_255: AlvMaaritys = { tunniste: 'o255', kirjaustili: '1763', nimi: 'Osto 25,5%', lyhenne: 'O255', prosentti: 25.5, kaannetty: false }
  public static readonly OSTO_100: AlvMaaritys = { tunniste: 'o100', kirjaustili: '1763', nimi: 'Osto 100%', lyhenne: 'O100', prosentti: 100, kaannetty: false }
  public static readonly OSTO_EU_TAVARA_10: AlvMaaritys = { tunniste: 'oet10', kirjaustili: '1763', nimi: 'EU-tavaraosto 10%', lyhenne: 'OET10', prosentti: 10, kaannetty: true }
  public static readonly OSTO_EU_TAVARA_14: AlvMaaritys = { tunniste: 'oet14', kirjaustili: '1763', nimi: 'EU-tavaraosto 14%', lyhenne: 'OET14', prosentti: 14, kaannetty: true }
  public static readonly OSTO_EU_TAVARA_24: AlvMaaritys = { tunniste: 'oet24', kirjaustili: '1763', nimi: 'EU-tavaraosto 24%', lyhenne: 'OET24', prosentti: 24, kaannetty: true }
  public static readonly OSTO_EU_TAVARA_255: AlvMaaritys = { tunniste: 'oet255', kirjaustili: '1763', nimi: 'EU-tavaraosto 25,5%', lyhenne: 'OET255', prosentti: 25.5, kaannetty: true }
  public static readonly OSTO_EU_PALVELU_10: AlvMaaritys = { tunniste: 'oep10', kirjaustili: '1763', nimi: 'EU-palveluosto 10%', lyhenne: 'OEP10', prosentti: 10, kaannetty: true }
  public static readonly OSTO_EU_PALVELU_14: AlvMaaritys = { tunniste: 'oep14', kirjaustili: '1763', nimi: 'EU-palveluosto 14%', lyhenne: 'OEP14', prosentti: 14, kaannetty: true }
  public static readonly OSTO_EU_PALVELU_24: AlvMaaritys = { tunniste: 'oep24', kirjaustili: '1763', nimi: 'EU-palveluosto 24%', lyhenne: 'OEP24', prosentti: 24, kaannetty: true }
  public static readonly OSTO_EU_PALVELU_255: AlvMaaritys = { tunniste: 'oep255', kirjaustili: '1763', nimi: 'EU-palveluosto 25,5%', lyhenne: 'OEP255', prosentti: 25.5, kaannetty: true }
  public static readonly OSTO_EU_ULKOP_PALVELU_24: AlvMaaritys = { tunniste: 'oup24', kirjaustili: '1763', nimi: 'EU:n ulkopuolinen palveluosto 24%', lyhenne: 'OUP24', prosentti: 24, kaannetty: true }
  public static readonly OSTO_EU_ULKOP_PALVELU_255: AlvMaaritys = { tunniste: 'oup255', kirjaustili: '1763', nimi: 'EU:n ulkopuolinen palveluosto 25,5%', lyhenne: 'OUP255', prosentti: 25.5, kaannetty: true }
  public static readonly OSTO_EU_ULKOP_TUONTI_10: AlvMaaritys = { tunniste: 'otu10', kirjaustili: '1763', nimi: 'EU:n ulkopuolinen tuonti 10%', lyhenne: 'OTU10', prosentti: 10, kaannetty: true }
  public static readonly OSTO_EU_ULKOP_TUONTI_14: AlvMaaritys = { tunniste: 'otu14', kirjaustili: '1763', nimi: 'EU:n ulkopuolinen tuonti 14%', lyhenne: 'OTU14', prosentti: 14, kaannetty: true }
  public static readonly OSTO_EU_ULKOP_TUONTI_24: AlvMaaritys = { tunniste: 'otu24', kirjaustili: '1763', nimi: 'EU:n ulkopuolinen tuonti 24%', lyhenne: 'OTU24', prosentti: 24, kaannetty: true }
  public static readonly OSTO_EU_ULKOP_TUONTI_255: AlvMaaritys = { tunniste: 'otu255', kirjaustili: '1763', nimi: 'EU:n ulkopuolinen tuonti 25,5%', lyhenne: 'OTU255', prosentti: 25.5, kaannetty: true }
  public static readonly OSTO_RAKENNUSPALVELU_24: AlvMaaritys = { tunniste: 'or24', kirjaustili: '1763', nimi: 'Rakennuspalveluosto 24%', lyhenne: 'OR24', prosentti: 24, kaannetty: true }
  public static readonly OSTO_RAKENNUSPALVELU_255: AlvMaaritys = { tunniste: 'or255', kirjaustili: '1763', nimi: 'Rakennuspalveluosto 25,5%', lyhenne: 'OR255', prosentti: 25.5, kaannetty: true }

  public static kaikki: AlvMaaritys[] = [
    OstoAlvt.OSTO_0,
    OstoAlvt.OSTO_10,
    OstoAlvt.OSTO_14,
    OstoAlvt.OSTO_24,
    OstoAlvt.OSTO_255,
    OstoAlvt.OSTO_100,
    OstoAlvt.OSTO_EU_TAVARA_10,
    OstoAlvt.OSTO_EU_TAVARA_14,
    OstoAlvt.OSTO_EU_TAVARA_24,
    OstoAlvt.OSTO_EU_TAVARA_255,
    OstoAlvt.OSTO_EU_PALVELU_10,
    OstoAlvt.OSTO_EU_PALVELU_14,
    OstoAlvt.OSTO_EU_PALVELU_24,
    OstoAlvt.OSTO_EU_PALVELU_255,
    OstoAlvt.OSTO_EU_ULKOP_PALVELU_24,
    OstoAlvt.OSTO_EU_ULKOP_PALVELU_255,
    OstoAlvt.OSTO_EU_ULKOP_TUONTI_10,
    OstoAlvt.OSTO_EU_ULKOP_TUONTI_14,
    OstoAlvt.OSTO_EU_ULKOP_TUONTI_24,
    OstoAlvt.OSTO_EU_ULKOP_TUONTI_255,
    OstoAlvt.OSTO_RAKENNUSPALVELU_24,
    OstoAlvt.OSTO_RAKENNUSPALVELU_255
  ]

  public static onkoOstoAlv(tunniste: string): boolean {
    return !!this.kaikki.find(a => a.tunniste === tunniste)
  }

  private static _cutOffDate: NumberDate = 240831
  public static flip24PercentageTo255AndViceVersaBasedOnDate(alv: AlvMaaritys, date: NumberDate): AlvMaaritys {

    if (date > this._cutOffDate) {
      if (alv.tunniste === this.OSTO_24.tunniste) {
        return this.OSTO_255
      } else if (alv.tunniste === this.OSTO_EU_TAVARA_24.tunniste) {
        return this.OSTO_EU_TAVARA_255
      } else if (alv.tunniste === this.OSTO_EU_PALVELU_24.tunniste) {
        return this.OSTO_EU_PALVELU_255
      } else if (alv.tunniste === this.OSTO_EU_ULKOP_PALVELU_24.tunniste) {
        return this.OSTO_EU_ULKOP_PALVELU_255
      } else if (alv.tunniste === this.OSTO_EU_ULKOP_TUONTI_24.tunniste) {
        return this.OSTO_EU_ULKOP_TUONTI_255
      } else if (alv.tunniste === this.OSTO_RAKENNUSPALVELU_24.tunniste) {
        return this.OSTO_RAKENNUSPALVELU_255
      }
    } else {
      if (alv.tunniste === this.OSTO_255.tunniste) {
        return this.OSTO_24
      } else if (alv.tunniste === this.OSTO_EU_TAVARA_255.tunniste) {
        return this.OSTO_EU_TAVARA_24
      } else if (alv.tunniste === this.OSTO_EU_PALVELU_255.tunniste) {
        return this.OSTO_EU_PALVELU_24
      } else if (alv.tunniste === this.OSTO_EU_ULKOP_PALVELU_255.tunniste) {
        return this.OSTO_EU_ULKOP_PALVELU_24
      } else if (alv.tunniste === this.OSTO_EU_ULKOP_TUONTI_255.tunniste) {
        return this.OSTO_EU_ULKOP_TUONTI_24
      } else if (alv.tunniste === this.OSTO_RAKENNUSPALVELU_255.tunniste) {
        return this.OSTO_RAKENNUSPALVELU_24
      }
    }

    return alv
  }

}

// Myynti:

// Myynti 0%              M0
// Myynti 10%             M10
// Myynti 14%             M14
// Myynti 24%             M24

// EU-tavara 10%          MET10
// EU-tavara 14%          MET14
// EU-tavara 24%          MET24
// EU-palvelu 10%         MEP10
// EU-palvelu 14%         MEP14
// EU-palvelu 24%         MEP24

// 0-verokannan alainen liikevaihto (vienti)    MVIE
// Rakentamispalvelun myynti                    MRAK
export class MyyntiAlvt {

  /**
   * NOTE! NOTE! While lyhenne and tunniste can be different, both must be UNIQUE!
   */

  public static readonly MYYNTI_0_VEROKANNAN_ALAINEN_LV: AlvMaaritys = { tunniste: 'vienti', kirjaustili: '2939', nimi: '0-verokannan alainen lv', lyhenne: 'VIENTI', prosentti: 0, kaannetty: false }
  public static readonly MYYNTI_0: AlvMaaritys = { tunniste: 'm0', kirjaustili: '2939', nimi: 'Myynti 0%', lyhenne: 'M0', prosentti: 0, kaannetty: false }
  public static readonly MYYNTI_10: AlvMaaritys = { tunniste: 'm10', kirjaustili: '2939', nimi: 'Myynti 10%', lyhenne: 'M10', prosentti: 10, kaannetty: false }
  public static readonly MYYNTI_14: AlvMaaritys = { tunniste: 'm14', kirjaustili: '2939', nimi: 'Myynti 14%', lyhenne: 'M14', prosentti: 14, kaannetty: false }
  public static readonly MYYNTI_24: AlvMaaritys = { tunniste: 'm24', kirjaustili: '2939', nimi: 'Myynti 24%', lyhenne: 'M24', prosentti: 24, kaannetty: false }
  public static readonly MYYNTI_255: AlvMaaritys = { tunniste: 'm255', kirjaustili: '2939', nimi: 'Myynti 25,5%', lyhenne: 'M255', prosentti: 25.5, kaannetty: false }
  public static readonly MYYNTI_EU_TAVARA_10: AlvMaaritys = { tunniste: 'met10', kirjaustili: '2939', nimi: 'EU-tavaramyynti 10%', lyhenne: 'MET10', prosentti: 0, kaannetty: false }
  public static readonly MYYNTI_EU_TAVARA_14: AlvMaaritys = { tunniste: 'met14', kirjaustili: '2939', nimi: 'EU-tavaramyynti 14%', lyhenne: 'MET14', prosentti: 0, kaannetty: false }
  public static readonly MYYNTI_EU_TAVARA_24: AlvMaaritys = { tunniste: 'met24', kirjaustili: '2939', nimi: 'EU-tavaramyynti 24%', lyhenne: 'MET24', prosentti: 0, kaannetty: false }
  public static readonly MYYNTI_EU_TAVARA_255: AlvMaaritys = { tunniste: 'met255', kirjaustili: '2939', nimi: 'EU-tavaramyynti 25,5%', lyhenne: 'MET255', prosentti: 0, kaannetty: false }
  public static readonly MYYNTI_EU_PALVELU_10: AlvMaaritys = { tunniste: 'mep10', kirjaustili: '2939', nimi: 'EU-palvelumyynti 10%', lyhenne: 'MEP10', prosentti: 0, kaannetty: false }
  public static readonly MYYNTI_EU_PALVELU_14: AlvMaaritys = { tunniste: 'mep14', kirjaustili: '2939', nimi: 'EU-palvelumyynti 14%', lyhenne: 'MEP14', prosentti: 0, kaannetty: false }
  public static readonly MYYNTI_EU_PALVELU_24: AlvMaaritys = { tunniste: 'mep24', kirjaustili: '2939', nimi: 'EU-palvelumyynti 24%', lyhenne: 'MEP24', prosentti: 0, kaannetty: false }
  public static readonly MYYNTI_EU_PALVELU_255: AlvMaaritys = { tunniste: 'mep255', kirjaustili: '2939', nimi: 'EU-palvelumyynti 25,5%', lyhenne: 'MEP255', prosentti: 0, kaannetty: false }
  public static readonly MYYNTI_RAKENNUSPALVELU_0: AlvMaaritys = { tunniste: 'mrp0', kirjaustili: '2939', nimi: 'Rakennuspalvelumyynti 0%', lyhenne: 'MRP0', prosentti: 0, kaannetty: false }
  // public static readonly MYYNTI_RAKENNUSPALVELU_24:      AlvMaaritys = { tunniste: 'mrp24',  kirjaustili: '2939', nimi: 'Rakennuspalvelumyynti 24%',   lyhenne: 'MRP24',      prosentti: 24, kaannetty: false }

  public static suomi: AlvMaaritys[] = [
    MyyntiAlvt.MYYNTI_0,
    MyyntiAlvt.MYYNTI_10,
    MyyntiAlvt.MYYNTI_14,
    MyyntiAlvt.MYYNTI_24,
    MyyntiAlvt.MYYNTI_255
  ]

  public static kaikki: AlvMaaritys[] = [
    MyyntiAlvt.MYYNTI_0,
    MyyntiAlvt.MYYNTI_10,
    MyyntiAlvt.MYYNTI_14,
    MyyntiAlvt.MYYNTI_24,
    MyyntiAlvt.MYYNTI_255,
    MyyntiAlvt.MYYNTI_EU_TAVARA_10,
    MyyntiAlvt.MYYNTI_EU_TAVARA_14,
    MyyntiAlvt.MYYNTI_EU_TAVARA_24,
    MyyntiAlvt.MYYNTI_EU_TAVARA_255,
    MyyntiAlvt.MYYNTI_EU_PALVELU_10,
    MyyntiAlvt.MYYNTI_EU_PALVELU_14,
    MyyntiAlvt.MYYNTI_EU_PALVELU_24,
    MyyntiAlvt.MYYNTI_EU_PALVELU_255,
    MyyntiAlvt.MYYNTI_0_VEROKANNAN_ALAINEN_LV,
    MyyntiAlvt.MYYNTI_RAKENNUSPALVELU_0
    // MyyntiAlvt.MYYNTI_RAKENNUSPALVELU_24
  ]

  public static onkoMyyntiAlv(tunniste: string): boolean {
    return !!this.kaikki.find(a => a.tunniste === tunniste)
  }

  private static _cutOffDate: NumberDate = 240831
  public static flip24PercentageTo255AndViceVersaBasedOnDate(alv: AlvMaaritys, date: NumberDate): AlvMaaritys {

    if (date > this._cutOffDate) {
      if (alv.tunniste === this.MYYNTI_24.tunniste) {
        return this.MYYNTI_255
      } else if (alv.tunniste === this.MYYNTI_EU_TAVARA_24.tunniste) {
        return this.MYYNTI_EU_TAVARA_255
      } else if (alv.tunniste === this.MYYNTI_EU_PALVELU_24.tunniste) {
        return this.MYYNTI_EU_PALVELU_255
      }
    } else {
      if (alv.tunniste === this.MYYNTI_255.tunniste) {
        return this.MYYNTI_24
      } else if (alv.tunniste === this.MYYNTI_EU_TAVARA_255.tunniste) {
        return this.MYYNTI_EU_TAVARA_24
      } else if (alv.tunniste === this.MYYNTI_EU_PALVELU_255.tunniste) {
        return this.MYYNTI_EU_PALVELU_24
      }
    }

    return alv
  }

}

// asiakkaat/{asiakasavain}/kirjanpito-kirjattavat-kuitit
export interface BaseKirjattavaKuitti {
  avain: string
  kirjattuPvm: Timestamp | null
  poistettu: boolean
  paivittynyt: boolean
}

// asiakkaat/{asiakasavain}/kirjanpito-kirjattavat-kuitit/{kuittiAvain}/kirjanpito-kirjattavat-kuitit-historia
export interface KirjattavaKuittiHistory extends Omit<KirjattavaKuitti, 'kuitti'> {
  kuitti: FirestoreTosite
  muutoksenSyy: KirjauksenMuutoksenSyy
  tallennettu: Timestamp
  tallentaja: 'lemonator_system' | string
}

// asiakkaat/{asiakasavain}/kirjanpito-kirjattavat-kuitit
export interface KirjattavaKuitti extends BaseKirjattavaKuitti {
  kuitti: FirestoreTosite[]
  kirjauksissa?: { [kirjauksenAvain: string]: boolean }
  kirjanpitajanPoistama: boolean
  /** the date of the receipt from the latest receipt */
  pvm: NumberDate
  sum: number
  korostettu?: boolean
  /**
   * Onko jatkuva tosite
   */
  j?: boolean
  /**
   * Yliajosumma yhdistelyä varten
   */
  a?: number
  /** Electronic invoice, Viitenumero */
  ev?: string
  /** Is electronic invoice? */
  e?: true
}

export interface KirjattavaKuittiKirjauksessa extends BaseKirjattavaKuitti {
  kuitti: FirestoreTosite
}

// asiakkaat/{asiakasavain}/kirjanpito-kirjattavat-laskut
export interface BaseKirjattavaLasku {
  avain: string
  kirjattuPvm: Timestamp | null
  poistettu: boolean
  paivittynyt: boolean
}
export interface KirjattavaLasku extends BaseKirjattavaLasku {
  lasku: Lasku
  kirjauksissa?: { [kirjauksenAvain: string]: boolean }
  kirjanpitajanPoistama: boolean
  korostettu?: boolean
  /**
   * Onko jatkuva tosite
   */
  // j?: boolean
}

export interface KirjattavaLaskuHistory extends Omit<KirjattavaLasku, 'lasku'> {
  lasku: Lasku
  muutoksenSyy: KirjauksenMuutoksenSyy
  tallennettu: Timestamp
  tallentaja: 'lemonator_system' | string
}

export interface KirjattavaLaskuKirjauksessa extends BaseKirjattavaLasku {
  lasku: Lasku
}

export interface BaseKirjattavaUusi {
  /**
   * Poistettu kirjanpitäjän toimesta
   */
  p: boolean
  /**
   * Mihin kirjauksiin kirjattava on liitetty
   */
  k?: { [kirjauksenAvain: string]: boolean }
}

// asiakkaat/{asiakasavain}/kirjanpito-kirjattavat-raahatut
export interface KirjattavaRaahattuTiedosto extends BaseKirjattavaUusi {
  /**
   * Luotu millis
   */
  c: number
  /**
   * Luoneen käyttäjän uid
   */
  u: string
  /**
   * Payload
   */
  s: KirjaukseenLiitetynTiedostonSivu
  /**
   * Onko liitetty kirjaukseen
   */
  l: boolean
  /**
   * Tiedostonimi
   */
  n: string
  /**
   * Alkuperäinen Mime type
   */
  m: string
  /**
   * Onko korostettu
   */
  ko?: boolean
  /**
   * Onko jatkuva tosite
   */
  j?: boolean
  /**
   * Yliajosumma yhdistelyä varten
   */
  a?: number
}

export interface KirjattavaRaahattuTiedostoHistory extends KirjattavaRaahattuTiedosto {
  muutoksenSyy: KirjauksenMuutoksenSyy
  tallennettu: Timestamp
  tallentaja: 'lemonator_system' | string
}

export interface AlvIlmoituksenTunnistetiedot {
  vuosi: number
  jarjestys: number
  alvIlmoitusjakso: AlvIlmoitusjakso
}

// tyojono/{asiakasavain}/kirjanpito-alv-ilmoituksen-lahetys-ilmoitin/{asiakasavain}
export interface AlvIlmoitusLahetaIlmoitinTyo {
  luotu: Timestamp
  asiakasAvain: string
}

// tyojono/{asiakasavain}/kirjanpito-veroilmoituksen-lahetys-ilmoitin/{asiakasavain}
export interface VeroilmoitusLahetaIlmoitinTyo {
  luotu: Timestamp
  asiakasAvain: string
}

// tyojono/{asiakasavain}/kirjanpito-alv-ilmoituksen-lahetys-uusi/{alv-ilmoituksen-avain}
export interface AlvIlmoitusLahetaTyoUusi {
  luotu: Timestamp
  lahetettavanAlvIlmoituksenAvain: string
  edellisenAlvIlmoituksenAvain: string
}

export enum AlvIlmoituksenLahetystila {

  /**
   * Kirjanpitaja ei ole hyväksynyt ALV-ilmoitusta lähetettäväksi.
   */
  EI_HYVAKSYTTY = 'a',

  /**
   * ALV-ilmoitus on hyväskytty, mutta ei vielä lähetetty.
   */
  EI_LAHETETTY = 'b',

  /**
   * ALV-ilmoitus on lähetetty verottajalle.
   */
  LAHETETTY = 'c'

}

// asiakkaat/{asiakasavain}/kirjanpito-alvilmoitukset
export interface AlvIlmoitus extends IlmoitinIntegrationEntity {

  avain: string
  tunniste: string
  jarjestysnumero: number
  tunnistetiedot: AlvIlmoituksenTunnistetiedot

  alv255: number
  alv24: number
  alv14: number
  alv10: number

  taveu: number
  palveu: number
  taveieu: number
  rak: number

  vahennettava: number
  edellinenMaksettavaTaiPalautettavaVero: number

  nolla: number
  tavmyynniteu: number
  palvmyynniteu: number
  tavostoeu: number
  palvostoeu: number
  tavulkeu: number
  rakmetmyynti: number
  rakmetosto: number

  startDate: LocalDate
  endDate: LocalDate

  tallennettu: Timestamp
  tallentaja: string
  luotu: Timestamp
  luoja: string

  /** Lukitaan tilinpäätöksen jälkeen */
  lukittu: boolean

  automaattikirjaus2939Summa: number
  automaattikirjaus1763Summa: number

  arhError?: AlarajahuojennusError
  arhLaskettu?: AlvIlmoituksenAlarajahuojennuslaskelma
  arhSyotetty?: {
    suhteutettuLiikevaihto?: number
    vero?: number
    huojennus?: number
  }

  alvLasku?: {
    erapaiva: LocalDate
    viitenumero: string
    maksettavaMaara: number
    lahetetty: Timestamp
  }

}

export interface AlvIlmoituksenAlarajahuojennuslaskelma {

  alvKausi: AlvIlmoitusjakso
  liikevaihdonSummatTileista: { kredit: number, debet: number }
  verojenSummatTileista: { ostoKredit: number, ostoDebet: number, myyntiDebet: number, myyntiKredit: number }
  valisummat: {
    liikevaihto: number
    ostoverot: number
    myyntiverot: number
  }

  suhteutettuLiikevaihto: number
  vero: number
  huojennus: number

}

export type AlarajahuojennusError = 'no-alv' | 'alv-change-during-tilikausi' | 'calculation-not-supported-for-alv-type' | 'not-eligible-due-to-too-short-tilikausi'

export interface IlmoitinIlmoituksenLahetysvirhe {
  virhe: string
  tapahtunut: Timestamp
}

export interface AlvTilienArhPaattaminenIlmoituksenPerusteellaPyynto {
  asiakasAvain: string
  ilmoitus: AlvIlmoitus
}

export interface AlvTilienPaattaminenPyynto {
  asiakasAvain: string
  kuukausi: LocalMonth
}

export interface AlvTilienPaattaminenVastaus extends ErrorResponse {

}

export interface AlvIlmoituksenTyviTiedostonLatauspyynto {
  asiakasAvain: string
  alvIlmoitusAvain: string
}

export interface AlvIlmoituksenTyviTiedostonLatausvastaus {
  /** Huom - tämä on tiedoston sisältö stringinä, EI base64 encoodattuna. */
  tiedosto: string
  nimi: string
}

// TODO: NOT IN USE ANYWHERE??
export interface LisattavaBase64Tiedosto {
  base64Sisalto: string
  mimeType: string
  fileName: string
}

export interface KirjauksenTallennuspyyntoUusi {
  asiakasAvain: string
  kirjaus: Kirjaus
  /**
   * Jos null, haetaan viimeisimmät tietokannasta.
   */
  liitetyt: KirjaukseenLiitettyjenTiedostojenSivut
  uusiPaivamaara?: number
  uusiMaksutapaTunniste?: string
  uusiViitenumero?: string
  skipAccountBalanceCheck?: true

  // Poistot
  poistokirjauksienLuontiasetukset?: PoistokirjauksienLuontiasetukset
  poistotilienMuutostiedot?: PoistotilienMuutostiedot
}

export interface PoistokirjauksienLuontiasetukset {
  poistettavaTili: string,
  poistettavaMaara: number,
  poistoprosentti: number
  poistotili: string,
  suhteutaPoisto: boolean
  jaannosViimeiselleKuukaudelle: boolean
  onkoPoistettavaTiliRiviKredit: boolean
}

export interface PoistotilienMuutostiedot {
  tyyppi: 'poistotili-poistetaan-kirjauksesta' | 'poistotilillista-kirjausta-muokataan' | 'kirjaukseen-lisataan-poistotili' | 'ei-poistotileja'
  edellinenPoistotili: string | null
  nykyinenPoistotili: string | null
}

export interface MuutaKirjausLaskuperusteiseksiPyynto extends KirjauksenTallennuspyyntoUusi {
  uudenKirjauksenPaivamaara: NumberDate
}

export interface KirjauksenJaksotuspyynto {
  asiakasAvain: string
  kirjaus: Kirjaus
  jaksotuksenPaivamaara: number
  jaksotuksenMaksutapaTunniste: string
}

export interface KirjauksenJaksotuksenPoistopyynto {
  asiakasAvain: string
  kirjausAvain: string
  jaksotus: KirjauksenJaksotustiedot
}

export interface KirjauksenKulunJaksotusRequest {
  asiakasAvain: string
  kirjaus: Kirjaus
  kulurivi: Kirjausrivi
  alku: NumberMonth
  loppu: NumberMonth
}

export interface KirjauksenKulunJaksotusRequest {
  asiakasAvain: string
  kirjaus: Kirjaus
  alku: NumberDate
  loppu: NumberDate
}

export interface KirjauksenLiitetiedostonPoistopyynto {
  asiakasAvain: string
  kirjausAvain: string
  kuvaAvain: string
}

export interface KirjauksenKuitinTallennuspyynto {
  asiakasAvain: string
  kirjausAvain: string
  kuitinAvain: string
}

export interface KirjauksenRaahattavanTallennuspyynto {
  asiakasAvain: string
  kirjausAvain: string
  raahattavanAvain: string
}

export interface KirjauksenLaskunTallennuspyynto {
  asiakasAvain: string
  kirjausAvain: string
  laskuAvain: string
}


export interface KirjauksenLiitetiedostonTallennuspyynto {
  asiakasAvain: string
  kirjausAvain: string
  kuvanAvain: string
  base64Sisalto: string
  mimeType: string
  fileName: string
}

export interface AlvIlmoituksenLahetyspyynto {
  asiakasAvain: string
  valittuKuukausi: LocalMonth
  ilmoitus: AlvIlmoitus
}

export interface AlvIlmoituksenLahetysvastaus { }

export interface KirjauksenPoistopyyntoUusi {
  asiakasAvain: string
  kirjausAvain: string
}
export interface KirjauksenPoistopyynto {
  asiakasAvain: string
  kirjaus: Kirjaus
}
export interface KirjauksenPoistovastaus {

}
export interface KirjattavanKuitinPoistoPyynto {
  asiakasAvain: string
  kuittiAvain: string
}
export interface KirjattavanKuitinPoistoVastaus {
}

export interface KirjattavanLaskunPoistoPyynto {
  asiakasAvain: string
  laskuAvain: string
}

export interface KirjattavanRaahatunTiedostonPoistoPyynto {
  asiakasAvain: string
  raahatunAvain: string
}

export interface JatkuvaTositeMuutosPyynto {
  asiakasAvain: string
  kuittiAvain?: string
  raahatunAvain?: string
  yliajosumma?: number
}

export interface PaivitaTositteenKorostusPyynto {
  asiakasAvain: string
  korosta: boolean
  kuittiAvain?: string
  laskunAvain?: string
  raahatunAvain?: string
}


export interface KirjattavanLaskunPoistoVastaus {

}

export interface KirjauksenTositteenLiitospyynto {
  asiakasAvain: string
  kirjaus: Kirjaus
  validointiOk: boolean
}

export interface KirjauksenTositteenLiitoksenIrroituspyynto {
  asiakasAvain: string
  kirjaus: Kirjaus
  kirjattavaLaskuAvain?: string
  kirjattavaFirestoreTositeAvain?: string
  kirjattavaLisatositeAvain?: string
  validointiOk: boolean
}

export interface KirjauksenTallennusvastaus { }

export interface HolviImportFromTempFilePyynto {
  asiakasId: string
  asiakasAvain: string
  maksutapa: number
  tiedostonAvain: string
}

export interface HolviImportPyynto {
  asiakasId: string
  asiakasAvain: string
  maksutapa: number
  base64File: string
}

export interface HolviImportVastaus {
  onnistui: boolean
  kasiteltyjenTositteidenMaara: number
}
export interface FlatattuHierarkia {
  level: number
  kirjanpitotili: Kirjanpitotili
}

export interface KirjanpitoEmailLahetysTyojono {
  luotu: Timestamp
  asiakasAvain: string
  kirjanpitoEmailLahetysAvain: string
}
export interface VeroilmoitusEmailLahetysTyojono {
  luotu: Timestamp
  asiakasAvain: string
  veroilmoitusEmailLahetysAvain: string
}

export interface VeroilmoitusEmailLahetys {
  avain: string
  kuukausi: LocalMonth
  tunniste: string
  luoja: string
  luotu: Timestamp
  lahetetty: Timestamp
  recipients: EmailLahetysStatus[]
  aihe: string
  teksti: string
  kieli: TuettuKieli
  liitaTuloslaskelma: boolean
  liitaVirallinenTuloslaskelma: boolean
  liitaVirallinenTase: boolean
  liitaVeroilmoitus: boolean
  liitaTaseErittely: boolean
  taseErittely?: TilinpaatosTaseErittely
  veroilmoitusAvain?: string
  veroilmoitus?: VeroilmoitusFinal
  lisaaPiilokopio: boolean
  isHolviClient?: boolean
}
export interface KirjanpitoEmailLahetys {
  avain: string
  kuukausi: LocalMonth
  tunniste: string
  luoja: string
  luotu: Timestamp
  lahetetty: Timestamp
  recipients: EmailLahetysStatus[]
  kieli: TuettuKieli
  aihe: string
  teksti: string
  liitaSelvitettavatOstot: boolean
  liitaSelvitettavatMyynnit: boolean
  liitaTuloslaskelma: boolean
  liitaTase: boolean
  liitaPaakirja: boolean
  liitaAlvIlmoitus: boolean
  alvIlmoitus: AlvIlmoitus
  lisaaPiilokopio: boolean
  isHolviClient?: boolean
}

export interface EmailLiite {
  avain: string
  nimi: string
  /** Size in bytes */
  koko: number
  fileType: string
  ladattu: Timestamp
  poistettu: boolean
}

export interface KirjanpitoEmailLahetysHistoryPdfRequest {
  asiakasAvain: string
  reportType: RaporttiName
  lahetysAvain: string
}
export interface VeroilmoitusEmailLahetysHistoryPdfRequest {
  asiakasAvain: string
  reportType: VirallinenRaporttiName
  lahetysAvain: string
}

export interface TilinpaatosEmailLahetysHistoryPdfRequest {
  asiakasAvain: string
  reportType: TilinpaatosRaporttiName
  lahetysAvain: string
}

export interface EmailLiiteDownloadRequest {
  asiakasAvain: string
  liite: EmailLiite
}

export interface KirjanpidonLadattavanAineistonLinkkipyynto {
  asiakasAvain: string
  ladattavanAineistonAvain: string
}

export interface KirjanpidonLadattavanAineistonLinkkivastaus {
  url: string
}

export interface TilinpaatosEmailLahetysTyojono {
  luotu: Timestamp
  asiakasAvain: string
  tilinpaatosEmailLahetysAvain: string
}

export interface TilinpaatosLahetysTyojono {
  luotu: Timestamp
  asiakasAvain: string
  tilinpaatosLahetysAvain: string
}

export interface TilinpaatosLahetys {
  avain: string
  tilikausi: Tilikausi
  luoja: string
  luotu: Timestamp
  lahetetty: Timestamp
}

export interface TilinpaatosEmailLahetys {
  avain: string
  tilikausi: Tilikausi
  tunniste: string
  luoja: string
  luotu: Timestamp
  lahetetty: Timestamp
  recipients: EmailLahetysStatus[]
  kieli: TuettuKieli
  aihe: string
  teksti: string
  liitaTuloslaskelma: boolean
  liitaTilinpaatos: boolean
  liitaTaseErittely: boolean
  lisaaPiilokopio: boolean
  isHolviClient?: boolean
}

export interface TilinpaatosEmailTextAutosaveData {
  lahetysAvain: string
  aihe: string
  tekstinAlku: string
  tekstinLoppu: string
  /**
  * Kirjanpitäjän avain
  */
  paivittaja: string
  paivitettu: Timestamp
  piilokopioValittu: boolean
  tulosValittu: boolean
  taseErittelyValittu: boolean
  tilinpaatosValittu: boolean
}

export interface VeroilmoitusEmailTextAutosaveData {
  lahetysAvain: string
  aihe: string
  tekstinAlku: string
  tekstinLoppu: string
  /**
  * Kirjanpitäjän avain
  */
  paivittaja: string
  paivitettu: Timestamp
  piilokopioValittu: boolean
  tulosValittu: boolean
  virTulosValittu: boolean
  virTaseValittu: boolean
  veroilmoitusValittu: boolean
}

export interface KirjanpitoEmailTextAutosaveData {
  lahetysAvain: string
  aihe: string
  tekstinAlku: string
  tekstinLoppu: string

  aiheet: Partial<MonikielinenTeksti>
  tekstinAlut: Partial<MonikielinenTeksti>
  tekstinLoput: Partial<MonikielinenTeksti>
  /**
  * Kirjanpitäjän avain
  */
  paivittaja: string
  paivitettu: Timestamp
  piilokopioValittu: boolean
  paakirjaValittu: boolean
  taseValittu: boolean
  tulosValittu: boolean
  alvValittu: boolean
  selvOstotValittu: boolean
  selvMyynnitValittu: boolean
}

export interface KirjanpidonProjekti {
  avain: string
  /**
  * Is this project usable in Lemonator?
  */
  usableInLemonator: boolean
  /**
  * Is this project viewable in Lemonator?
  */
  viewableInLemonator: boolean
  /**
  * Is this project visible in Lemonaid? (implemented later)
  */
  viewableInLemonaid: boolean
  /**
  * TODO: Harmonize implementation with other color mechanisms?
  */
  color: string
  nimi: string
}

export interface VeroilmoituksenAvainluvut { [verohallinnonNumero: string]: number | string }

// TODO: When upgrading to TS 4.1, explore the possibilities of new ways
// to declare types using template literals: as `t${string}`
// type test = { [tilinumero: string as `t${string}`]: number }
// should be possible, which would limit the possible properties to
// any string starting with a t.
export interface TilienSummat { [tilinumero: string]: number }

export enum TilienSummanErityismarker {
  /**
   * Summa tilille, josta on suodatettu pois tilit tyhjentävä automaattikirjaus.
   */
  TMI_234_SUODATETTU = '_skip234'
}

/**
 * asiakkaat/{asiakasAvain}/kirjanpito-tilikauden-summat/{tilikausiAvain}
 */
export interface TilikaudenSummat {

  asiakasAvain: string
  tilikausiAvain: string

  /**
   * Kaikkien kirjanpitotilien summat tilikaudelle.
   */
  tilikaudenSummat: TilienSummat

  /**
   * Kredit summa vuodelle, jossa EI ole mukana nollauskirjausta.
   */
  kalenterivuoden234KreditSumma: number

  /**
   * Debet summa vuodelle, jossa EI ole mukana nollauskirjausta.
   */
  kalenterivuoden234DebetSumma: number

}

/**
 * asiakkaat/{asiakasAvain}/kirjanpito-veroilmoitus-draft/{tilikausiAvain}
 */
export type VeroilmoitusOsingonjakoNotificationType =
  'has-not-been-reported-lemonator-added-resend' |
  'has-not-been-reported-lemonator-did-not-add-noop' |
  'has-been-reported-poytakirja-says-not-check-correctness' |
  'has-been-reported-poytakirja-says-yes-check-correctness'
export interface VeroilmoitusDraft {
  asiakasAvain: string
  tilikausiAvain: string
  arvotEditable: VeroilmoituksenAvainluvut
  punakynaNumbers: { [key: string]: 1 }
  osingonjakoNotification?: VeroilmoitusOsingonjakoNotificationType
  osakkaat: VeroilmoitusOsakas[]
}

export interface VeroilmoitusOsakkaatHistoria {
  osakkaat: VeroilmoitusOsakas[]
  muokattu: Timestamp
  muokkaaja: string
}

export interface VeroilmoitusOsakas extends Pick<CustomerOsakas, 'nimi' | 'hetuTaiYtunnus' | 'osakkeita'> {
}

export interface IlmoitinIntegrationEntity {
  lahetetty: Timestamp
  lahetysvirheet?: IlmoitinIlmoituksenLahetysvirhe[]
  successData?: string
}

/**
 * asiakkaat/{asiakasAvain}/kirjanpito-veroilmoitus-final/{randomkey}
 */
export interface VeroilmoitusFinal extends IlmoitinIntegrationEntity, VeroilmoitusDraft {
  avain: string
  year: number // Format yyyy
  created: Timestamp
  creator: string
  arvot: IlmoitusRivi[]
}

export enum VeroilmoituksenValueConstants {
  TRUE = '1',
  FALSE = '0'
}

export type RaporttiName = 'paakirja' | 'tuloslaskelma' | 'tase' | 'alv-ilmoitus' | 'selvitettavat-ostot' | 'selvitettavat-myynnit' | 'paivakirja'
export type VirallinenRaporttiName = 'tuloslaskelma' | 'virallinen-tulos' | 'virallinen-tase' | 'veroilmoitus' | 'tase-erittely'
export type TilinpaatosRaporttiName = 'tuloslaskelma' | 'tilinpaatos' | 'tase-erittely'


export interface RaporttiRequest {

  /** Asiakas avain */
  a: string
  /** Kieli */
  k: TuettuKieli
  /** The type of report */
  w: RaporttiType

  /** Start, format yymmdd */
  s?: NumberDate
  /** End, format yymmdd */
  e?: NumberDate

  /** Force Whole tilikausi to be the vertailu column */
  fw?: 1

  /** return Detail rows? */
  d?: 1
  /** return Zero rows? */
  z?: 1
  /** return detail rows for account numbers? */
  f?: string[]
  /** return detail rows for account numbers, from times beginning? */
  ff?: string[]
  /** return kirjausavaimet */
  ka?: 1
  /** text search */
  t?: string
  /** limit to given project */
  p?: string
  /** From account */
  b?: string
  /** To account */
  c?: string

  /** Download time */
  l?: LocalDateTime

  /** Veroilmoitus avain */
  va?: string
  /** Final veroilmoitus */
  vi?: VeroilmoitusFinal

  /** Tase erittely */
  ee?: string

  /** ALV-ilmoitus avain */
  aa?: string
  /** ALV-ilmoitus */
  ai?: AlvIlmoitus

  /** TilikausiAvain */
  ta?: string

  /** tilinpaatosMetadatan viimeisin versioavain */
  tm?: string

  /** Kirjanpitotilin numero */
  rk?: Kirjanpitotili['numero']

  /** Reskontra vain avoimet */
  rv?: boolean

}

export interface RaporttiPdfResponse extends ErrorResponse<'view-not-allowed' | 'no-veroilmoitus-avain' | 'no-time-range' | 'no-tase-erittely' | 'no-alv-ilmoitus' | 'unknown-report' | 'ei-maksuyhteystietoja' | 'invalid-omaaloitteisetviite' | 'no-language-selected' | 'unknown-error' | 'tilikausi-not-found' | 'no-signing-data'> {
  base64File?: string
  fileName?: string
}

export enum RaporttiTuloslaskelmaErikoisrivi {
  TULOS_ENNEN_VEROJA = 'tuenve'
}

export interface RaporttiTuloslaskelmaAccountRow {

  /** level, depth */
  l: number

  /** account number */
  a: string

  s1: number // Sum value for column 1
  s2?: number // Sum value for column 2
  s3?: number // Sum value for column 3
  s4?: number // Sum value for column 4

  /** Is sum row? */
  s?: 1

  /** Details */
  d?: RaporttiDetailRow[]

  /** THESE BELOW ARE NEVER POPULATED BY BACKEND!! */

  /** Displayed name, like 3000 Myynti 24% */
  n?: string
  /** Is the row expanded? */
  e?: 1
  /** Is the data still loading for row expansion? */
  r?: 1
  /** Detail Kredit Sum */
  dks?: number
  /** Detail Debit Sum */
  dds?: number

}

/**  */
export interface RaporttiColumnInfo {
  /** range start date, yymmdd */
  s: NumberDate
  /** range end date, yymmdd */
  e: NumberDate
}

export interface RaporttiDetailRow {

  /** Kirjauksen Avain */
  ka?: string

  /** Debet */
  d?: number
  /** Kredit */
  k?: number
  /** Saldo */
  s: number

  /** kirjausNumero */
  n: string
  /** Pvm */
  p: number
  /** Maksutapa */
  m: string
  /** alv, Vat */
  v?: string
  /** Brojekti */
  b?: string
  /** maksunsaaja, payment Receiver */
  r?: string

  /** Accountants comment */
  a?: string
  /** lemonaid Comment */
  c?: string
  /** other comments, Explanations */
  e?: string
  /** type inherited from raportointikirjaus */
  rt?: RaportointikirjauksenTyyppi

  /** THESE BELOW ARE NEVER POPULATED BY BACKEND!! */

  /** onko rivi Laajennettu (Expanded?) */
  l?: true
  /** Maksutavan nimi */
  ma?: string

}

/** How many columns are in the report? */
export type TuloslaskelmaReportColumnCount = 'c1' | 'c2' | 'c3' | 'c4'

export interface RaporttiVirallinenTuloslaskelmaDataResponse extends ErrorResponse<'view-not-allowed' | 'no-date-range'> {
  data?: RaporttiVirallinenTuloslaskelmaData
}

export interface RaporttiBruttoTuloslaskelmaDataResponse extends ErrorResponse<'view-not-allowed' | 'no-date-range'> {
  data?: RaporttiBruttoTuloslaskelmaData
}

export interface RaporttiVirallinenTaselaskelmaDataResponse extends ErrorResponse<'view-not-allowed' | 'no-date'> {
  data?: RaporttiVirallinenTaselaskelmaData
}

export interface RaporttiTuloslaskelmaDataResponse extends ErrorResponse<'view-not-allowed' | 'no-date-range'> {
  data?: RaporttiTuloslaskelmaData
}

export enum TulosKolumnienAikavalivirhe {
  ANNETTU_AIKAVALI_EI_OLE_TILIKAUDELLA = 'a'
}

export interface RaporttiTuloslaskelmaData {
  c: TuloslaskelmaReportColumnCount
  r: RaporttiTuloslaskelmaAccountRow[]
  c1: RaporttiColumnInfo
  c2?: RaporttiColumnInfo
  c3?: RaporttiColumnInfo
  c4?: RaporttiColumnInfo
  e?: TulosKolumnienAikavalivirhe
}

/** How many columns are in the report? */
export type TaselaskelmaReportColumnCount = 'c1' | 'c2'

export interface RaporttiTaselaskelmaDataResponse extends ErrorResponse<'no-date' | 'view-not-allowed'> {
  data?: RaporttiTaselaskelmaData
}

export interface RaporttiTaselaskelmaData {
  c: TaselaskelmaReportColumnCount
  r: RaporttiTaselaskelmaAccountRow[]
  c1: RaporttiColumnInfo
  c2?: RaporttiColumnInfo
  e?: TaseKolumnienVirhe
}

export interface RaporttiPaakirjaDataResponse extends ErrorResponse<'view-not-allowed'> {
  data?: RaporttiPaakirjaData
}

export interface RaporttiPaakirjaData {
  r: RaporttiPaakirjaAccountRow[]
}

export interface RaporttiPaakirjaAccountRow {
  /** grand Parent */
  p: string

  /** account number */
  a: string

  /** Opening number */
  o: number

  /** Debet value */
  b: number

  /** Kredit value */
  k: number

  /** Saldo */
  s: number

  /** Rows */
  d?: RaporttiDetailRow[]

  /** THESE BELOW ARE NEVER POPULATED BY BACKEND!! */

  /** Displayed name, like 3000 Myynti 24% */
  n?: string
}

export interface RaporttiAlvLaskelmaDataResponse extends ErrorResponse<'view-not-allowed'> {
  data?: RaporttiAlvLaskelmaData
}
export interface RaporttiAlvLaskelmaData {
  myynnit: RaporttiAlvLaskelmaAlvData[]
  ostot: RaporttiAlvLaskelmaAlvData[]

  /** THESE BELOW ARE NEVER POPULATED BY BACKEND!! */

  myyntiSums?: {
    /** Basis */
    b: number

    /** Tax */
    t: number

    /** Sum */
    s: number
  }

  ostoSums?: {
    /** Basis */
    b: number

    /** Tax */
    t: number

    /** Sum */
    s: number
  }

}

export interface RaporttiAlvLaskelmaAlvData {

  /** AlvMaaritys.tunniste */
  alv: string
  rivit: RaporttiAlvLaskelmaRow[]

  /** THESE BELOW ARE NEVER POPULATED BY BACKEND!! */
  n?: string

  /** Basis */
  b?: number

  /** Tax */
  t?: number

  /** Sum */
  s?: number

}

export interface RaporttiAlvLaskelmaRow {

  /** AlvMaaritys.tunniste */
  alv: string

  /** account number */
  a: string

  /** Basis */
  b: number

  /** Tax */
  t: number

  /** Sum */
  s: number

  // /** Rows */
  d1?: RaporttiDetailRow[]
  d2?: RaporttiDetailRow[]

  /** THESE BELOW ARE NEVER POPULATED BY BACKEND!! */

  /** Displayed name, like 3000 Myynti 24% */
  n?: string

  /** Expanded */
  e?: 1

}

export interface RaporttiOssDataResponse extends ErrorResponse<'view-not-allowed'> {
  data?: RaporttiOssData
}

export interface RaporttiOssData {
  r: RaporttiOssCountryRow[]
}

/**
 *  | -> maa 1, alv kanta 1,    veroton  vero   verollinen
 */
export interface RaporttiOssCountryRow {

  /** Level */
  l: number

  /** Account */
  a?: string

  /** Maa, only populated for l === 2 */
  m?: string

  /** Vat, only populated for l === 2 */
  v?: string

  /** Without VAT value, only populated for l === 2 */
  w?: number

  /** Percentage, only populated for l === 2 */
  p?: number

  /** Rows, only populated for l === 2 */
  d?: RaporttiDetailRow[]

  /** THESE BELOW ARE NEVER POPULATED BY BACKEND!! */

  /** Displayed name, like Suomi (FIN) */
  n?: string
  /** Is the row expanded? */
  e?: 1
  /** Is the data still loading for row expansion? */
  r?: 1
}

export interface RaporttiPaivakirjaData {
  r: RaporttiPaivakirjaKirjausRow[]
}

export interface RaporttiPaivakirjaKirjausRow {
  /** Pvm in yyymmdd format */
  p: number

  /** Kirjausnumero */
  kn: string

  /** Maksutapa */
  mt: string

  /** Kirjaus total sum */
  s: number

  /** Payer/payee (maksaja) */
  m: string

  /** Comments (viesti) */
  v: string

  /** Viitenumero */
  vi: string

  /** Rows */
  d: RaporttiPaivakirjaDetailRow[]

}

export interface RaporttiPaivakirjaDetailRow extends Pick<RaporttiDetailRow, 'ka' | 'd' | 'k' | 'v' | 'a' | 'b'> {
  /** Tilin nimi */
  t: string
}

export interface RaporttiTaselaskelmaAccountRow {

  /** level, depth */
  l: number

  /** account number */
  a: string

  /** Opening value for column 1 */
  o1: number

  /** Opening value for column 2 */
  o2?: number

  /** Sum value for column 1 */
  s1: number

  /** Sum value for column 2 */
  s2?: number

  /** Muutos === s2 + s1 */
  m?: number

  /** Is sum row? */
  s?: 1

  /** Details */
  d?: RaporttiDetailRow[]

  /** THESE BELOW ARE NEVER POPULATED BY BACKEND!! */

  /** Displayed name, like 3000 Myynti 24% */
  n?: string
  /** Is the row expanded? */
  e?: 1
  /** Is the data still loading for row expansion? */
  r?: 1
  /** Detail Kredit Sum */
  dks?: number
  /** Detail Debit Sum */
  dds?: number
  /** Detail Saldo Sum */
  dss?: number

}

export interface RaporttiVirallinenTuloslaskelmaData {
  c1Header: string
  c2Header: string
  c: 'c1' | 'c2'
  data: RaporttiVirallinenTuloslaskelmarivi[]
  e?: TulosKolumnienAikavalivirhe
}

export interface RaporttiVirallinenTuloslaskelmarivi {
  /** Nimi */
  n: string
  /** Level */
  l: number
  /** Sum 1 */
  s1: number
  /** Sum 2 */
  s2: number
  /** Is summary row */
  s?: 1
}

export interface RaporttiBruttoTuloslaskelmaData {
  c1Header: string
  c2Header: string
  c: 'c1' | 'c2'
  data: RaporttiBruttoTuloslaskelmarivi[]
  e?: TulosKolumnienAikavalivirhe
}

export interface RaporttiBruttoTuloslaskelmarivi {
  /** Nimi */
  n: string
  /** Level */
  l: number
  /** Sum 1 */
  s1: number
  /** Sum 2 */
  s2: number
  /** Is summary row */
  s?: 1
}

export interface RaporttiVirallinenTaselaskelmarivi {
  /** Nimi */
  n: string
  /** Level */
  l: number
  /** Sum 1 */
  s1: number
  /** Sum 2 */
  s2: number
  /** Is heading row */
  h?: boolean
  /** Is summary row */
  s?: boolean
  /** Is vastaavaa/vastattavaa summary row */
  vs?: boolean
}

export enum TaseKolumnienVirhe {
  ANNETTU_PAIVA_EI_OLE_TILIKAUDELLA = 'a'
}

export interface RaporttiVirallinenTaselaskelmaData {
  e?: TaseKolumnienVirhe.ANNETTU_PAIVA_EI_OLE_TILIKAUDELLA
  c1Header: string
  c2Header: string
  c: 'c1' | 'c2'
  data: RaporttiVirallinenTaselaskelmarivi[]
}

export enum TilinKasittelyTaseErittelyssa {
  EI_ERITELLA = 'e',
  KAIKKI_TILIKAUDET = 'k',
  RAPORTIN_TILIKAUSI = 'r',
  VIIMEINEN_KUUKAUSI = 'v',
  ERILLINEN_LIITE = 'l',
  OMA_TEKSTI = 't'
}

export interface TilinpaatosTaseErittely {
  tilienKasittelyt: { [tilinumero: string]: TilinKasittelyTaseErittelyssa }
  tilienTekstit: { [tilinumero: string]: string }
  valitutAlkusaldot: { [tilinumero: string]: 1 }
  valitutViennit: { [tilinumero_kirjausnumero_index: string]: 1 }
  luotu: Timestamp
  luoja: string
  /** Keyed by the account row they are attached to */
  liitteet?: { [tilinumero: string]: TaseErittelyLiite[] }
  /** Jos tase-erittely päättyy ennen tilikauden loppua, on tässä päivämäärä */
  /** Nämä ovat siis sitä varten, että pitää tehdä välitilinpäätös */
  /** Varsinaiseen verotukseen sitten aina tilikauden mukaan */
  loppuu?: LocalDate
}

export interface TaseErittelyLiite {
  avain: string
  nimi: string
  /** Size in bytes */
  koko: number
  fileType: string
  ladattu: Timestamp
  poistettu: boolean
}


export enum KirjanpidonLadattavanAineistonTila {
  PROSESSOIDAAN = 'p',
  TARKISTETAAN = 't',
  OK = 'o',
  VIRHEELLINEN = 'v'
}

export enum KirjanpidonLadattavanAineistonTyyppi {
  KIRJANPITAJAN_TILAAMA = 'k'
}

// tyojonodata/{asiakasAvain}/downloadable-accounting-material-1-create/{materialAvain}
// tyojonodata/{asiakasAvain}/downloadable-accounting-material-2-check/{materialAvain}
export interface KirjanpidonLadattavanAineistonTyojonodata {
  asiakasAvain: string
  ladattavanAineistonAvain: string
  lisatty: Timestamp
}

/**
 * Zipin luominen
 * 1. Kirjoita työjono entry oikeaan paikkaan + listausdata entryn
 * 2. Ensimmäinen työjono luo zipin, muuttaa tilan listausdatassa, siirtää tyojonon eteenpäin
 * 3. Toinen työjono handler tarkistaa zipin, muuttaa tilan listausdatassa, poistaa tyojonon. (Retry -> poista epäonnistunut, luo uusi + työjonoentry alkuun, max 5 kierrosta?)
 *
 * Lataaminen
 * 4. Käyttäjä klikkaa listausdataa -> lähetä CF:lle pyynto. Vastaa signatulla CS urlilla
 * 5. Lataa tiedosto suoraan
 */
// customers/{asiakasAvain}/downloadable-accounting-material/{materialAvain}
export interface KirjanpidonLadattavaAineisto {
  /**
   * Milloin työ luotiin (Created)?
   */
  c: Timestamp
  /**
   * Missä tilasssa aineisto on?
   */
  b: KirjanpidonLadattavanAineistonTila
  /**
   * Milloin työ valmistui?
   */
  v: Timestamp
  /**
   * Tyyppi
   */
  t: KirjanpidonLadattavanAineistonTyyppi
  /**
   * Asiakkaan avain
   */
  a: string
  /**
   * Aineiston alkamispäivä yymmdd
   */
  s: number
  /**
   * Aineiston loppumispäivä yymmdd
   */
  e: number
  /**
   * Aineiston kieli
   */
  k: TuettuKieli
  /**
   * Ladattavan aineiston avain
   */
  l: string
  /**
   * Aineiston tilannut käyttäjä
   */
  u: string
}

export interface KirjanpitoAlustaRequest {
  asiakasAvain: string
  /** Format yymmdd */
  kirjanpidonAlku: NumberDate
}

export interface KirjanpitoKevytalustaRequest {
  asiakasAvain: string
  /** Format yymmdd */
  kirjanpidonAlku: NumberDate
  maksutavanTunniste: AsiakkaanMaksutapa['tunniste']
}

export interface KirjanpitoAlustaResponse extends ErrorResponse { }

export interface LuoUlkopuolisetKulutTaikalinkkiRequest {
  asiakasAvain: string
  verovuosi: number
}

export interface LuoUlkopuolisetKulutTaikalinkkiResponse extends ErrorResponse {
  taikalinkki: string
}

export enum TilinpaatosLiitetiedotOsio {
  PMA1_1_5 = 'PMA1_1_5',
  PMA3_1 = 'PMA3_1',
  PMA3_1_A = 'PMA3_1_A',
  PMA3_1_B = 'PMA3_1_B',
  PMA3_2 = 'PMA3_2',
  PMA3_3 = 'PMA3_3',
  PMA3_4 = 'PMA3_4',
  PMA3_6 = 'PMA3_6',
  PMA3_7 = 'PMA3_7',
  PMA3_8 = 'PMA3_8',
  PMA3_9 = 'PMA3_9',
  PMA3_10 = 'PMA3_10',
  PMA3_11 = 'PMA3_11',
  PMA3_12 = 'PMA3_12',
  PMA4_4_2 = 'PMA4_4_2',
  TILIKAUDEN_AIKANA_TAPAHTUNEET = 'tilikauden-aikana-tapahtuneet',
  TTL3 = 'TTL3',
  ALLEKIRJOITUKSET = 'ALLEKIRJOITUKSET'
}

export enum TilinpaatosLiitetiedotKentat {
  ONKO_MIKROYRITYS_TASEEN_LOPPUSUMMA_YLI = 'ONKO_MIKROYRITYS_TASEEN_LOPPUSUMMA_YLI',
  ONKO_MIKROYRITYS_LIIKEVAIHTO_YLI = 'ONKO_MIKROYRITYS_LIIKEVAIHTO_YLI',
  ONKO_MIKROYRITYS_HENKILOSTO_YLI = 'ONKO_MIKROYRITYS_HENKILOSTO_YLI',
  PMA3_1_A_1 = 'PMA3_1_A_1',
  PMA3_1_A_2 = 'PMA3_1_A_2',
  PMA3_1_A_3 = 'PMA3_1_A_3',
  PMA3_1_A_4 = 'PMA3_1_A_4',
  PMA3_1_A_5 = 'PMA3_1_A_5',
  PMA3_1_A_6 = 'PMA3_1_A_6',
  PMA3_1_B_1 = 'PMA3_1_B_1',
  PMA3_1_B_2 = 'PMA3_1_B_2',
  PMA3_1_B_3 = 'PMA3_1_B_3',
  PMA3_1_B_4 = 'PMA3_1_B_4',
  PMA3_1_B_5 = 'PMA3_1_B_5',
  PMA3_1_B_6 = 'PMA3_1_B_6',
  PMA3_2_1 = 'PMA3_2_1',
  PMA3_3_1 = 'PMA3_3_1',
  PMA3_4_1 = 'PMA3_4_1',
  PMA3_6_1 = 'PMA3_6_1',
  PMA3_7_1 = 'PMA3_7_1',
  PMA3_7_2 = 'PMA3_7_2',
  PMA3_7_3 = 'PMA3_7_3',
  PMA3_7_4 = 'PMA3_7_4',
  PMA3_8_1 = 'PMA3_8_1',
  PMA3_9_1 = 'PMA3_9_1',
  PMA3_10_1 = 'PMA3_10_1',
  PMA3_11_1 = 'PMA3_11_1',
  PMA3_12_1 = 'PMA3_12_1',
  TTL3_4 = 'TTL3_4',
  OSAKEANTI = 'OSAKEANTI',
  SVOP_LISAYKSET = 'SVOP_LISAYKSET',
  SVOP_VAHENNYKSET = 'SVOP_VAHENNYKSET',
  YHTIO_ON_MYYNYT_OSAKKEITAAN = 'YHTIO_ON_MYYNYT_OSAKKEITAAN',
  YHTIO_ON_OSTANUT_OSAKKEITAAN = 'YHTIO_ON_OSTANUT_OSAKKEITAAN',
  YHTIOLLA_PAAOMALAINAA = 'YHTIOLLA_PAAOMALAINAA',
  OSINKOJEN_MAARA = 'OSINKOJEN_MAARA',
  EDELLISEN_TILIKAUDEN_OIKAISUT = 'EDELLISEN_TILIKAUDEN_OIKAISUT',
  ALLEKIRJOITUSTEN_TEKSTI = 'ALLEKIRJOITUSTEN_TEKSTI',
  YHTION_OSAKKEIDEN_LUNASTUKSET_TAI_LUOVUTUKSET = 'YHTION_OSAKKEIDEN_LUNASTUKSET_TAI_LUOVUTUKSET'
}

export interface TilinpaatosLiitetiedot extends BaseEntity {
  valitut: Partial<Record<TilinpaatosLiitetiedotKentat, boolean>>
  tekstit: Partial<Record<TilinpaatosLiitetiedotKentat, string>>
  numerot: Partial<Record<TilinpaatosLiitetiedotKentat, number>>
  osionLisarivit: Partial<Record<TilinpaatosLiitetiedotOsio, LisatietoKentta[]>>
  lisatietokentat: LisatietoKentta[]
  omanPaaomanMuutoksetLaskelma: MuutoksetOmassaPaaomassaLaskelma
  omanPaaomanMuutoksetVertailulaskelma: MuutoksetOmassaPaaomassaLaskelma
  vertailutiedot: VertailutietojenLahde
  osingonjakoEsilaskettuMaara: NumberMoney
  osingonjakoEsilaskettuOsinkoPerOsake: NumberMoney
  osingonjakoEsilaskettuOsakkeidenLukumaara: number
  osingonjakoAnnettuMaara: NumberMoney
  osingonjako: OsingonjaonTyyppi
  osinkoPerOsake: NumberMoney
  osinkojaYhteensa: NumberMoney
  osingotNostettavissa: OsingotNostettavissaTyyppi
  osingotNostettavissaPvm: NumberDate
  tilintarkastetaan: boolean
  liitteet: TilinpaatosLiitetiedotBinaariliite[]
  jakokelpoinenOmaPaaomaRivit: TilinpaatosJakokelpoisenOmanPaaomanLisarivi[]
  kokoLomakeTallennettu: boolean
  allekirjoitetaanLemonaidissa: boolean
  /** Jos liitetiedot päättyy ennen tilikauden loppua, on tässä päivämäärä */
  /** Nämä ovat siis sitä varten, että pitää tehdä välitilinpäätös */
  /** Varsinaiseen verotukseen sitten aina tilikauden mukaan */
  loppuu?: LocalDate
}

export type VertailutietojenLahde = 'syotetaan-kasin' | 'aikaisemmasta' | 'ensimmainen-tilikausi'
export type OsingonjaonTyyppi = 'ei-jaeta' | 'esilaskettu-prosentti' | 'annettu-maara'
export type OsingotNostettavissaTyyppi = 'yhtiokokouksen-jalkeen' | 'pvm'

export interface LisatietoKentta {
  otsikko: string
  teksti: string
  tunnus: string
  poistettu: boolean
}
export interface TilinpaatosJakokelpoisenOmanPaaomanLisarivi {
  tunnus: string
  kuvaus: string
  maara: number
  poistettu: boolean
}

export interface TilinpaatosLiitetiedotBinaariliite {
  avain: string
  nimi: string
  /** Size in bytes */
  koko: number
  fileType: string
  ladattu: Timestamp
  poistettu: boolean
}

export interface MuutoksetOmassaPaaomassaLaskelma {

  header: string

  /** Haetaan tili 2000 edellisen tilikauden viimeiseltä päivältä, eli voidaan hakea edellisen tilikauden summadokusta.Jos edellistä tilikautta ei löydy, on lukema 0, 00. */
  osakepaaomaTilikaudenAlussa: number
  osakepaaomaTilikaudenAlussaNakyvissa: boolean

  /** Ohjataan yllä olevasta "Tilikaudella on tehty osakeanti" - täpästä */
  osakeanti: number
  osakeantiNakyvissa: boolean

  /** Osakepääoma tilikauden alussa + Osakeanti */
  osakepaaomaTilikaudenLopussa: number
  osakepaaomaTilikaudenLopussaNakyvissa: boolean

  /** Osakepääoma tilikauden lopussa */
  sidottuOmaPaaoma: number
  sidottuOmaPaaomaNakyvissa: boolean

  /** Sijoitetun vapaan oman pääoman rahasto tilikauden alussa: Otetaan edellisen vuoden liitetietojen "Sijoitetun vapaan oman pääoman rahasto tilikauden lopussa" - kohdasta.Jos edellistä vuotta ei ole näytetään 0, 00. */
  svopTilikaudenAlussa: number
  svopTilikaudenAlussaNakyvissa: boolean

  /** Lisäykset tilikaudella: Ohjataan yllä olevasta "Tilikaudella on tehty lisäys sijoitetun vapaan pääoman rahastoon" - täpästä */
  svopLisayksetTilikaudenAikana: number
  svopLisayksetTilikaudenAikanaNakyvissa: boolean

  /** Vähennykset tilikaudella: Ohjataan yllä olevasta "Tilikaudella on tehty vähennys sijoitetun vapaan pääoman rahastoon" - täpästä */
  svopVahennyksetTilikaudenAikana: number
  svopVahennyksetTilikaudenAikanaNakyvissa: boolean

  /** Sijoitetun vapaan oman pääoman rahasto tilikauden lopussa: Sijoitetun vapaan oman pääoman rahasto tilikauden alussa + Lisäykset tilikaudella */
  svopTilikaudenLopussa: number
  svopTilikaudenLopussaNakyvissa: boolean

  /** Edellisten tilikausien voitto(tappio) tilikauden alussa: = 2250 saldo tilikauden ensimmäisenä päivänä(huom! Edellisen tilikauden tulos autom.kirjaus pitää laskea mukaan saldoon, eli voi ottaa vaikka tilikauden toisen päivän saldon). */
  edellistenTilikausienVoittoTaiTappioTilikaudenAlussa: number
  edellistenTilikausienVoittoTaiTappioTilikaudenAlussaNakyvissa: boolean

  /** Osingonjako tilikaudella: Ohjataan yllä olevasta "Tilikaudella on jaettu osinkoja" - täpästä */
  osingonjakoTilikaudella: number
  osingonjakoTilikaudellaNakyvissa: boolean

  /** Edellisten tilikausien virheiden oikaisut: Ohjataan yllä olevasta "Tilikaudella on oikaistu edellisten tilikausien virheitä" - täpästä **/
  edellistenTilikausienVirheidenOikaisut: number
  edellistenTilikausienVirheidenOikaisutNakyvissa: boolean

  /** Tilikaudella hankitut omat osakkeet: Ohjataan yllä olevasta "Yhtiö on hankkinut tai luovuttanut tilikaudella omia osakkeitaan" - täpästä */
  tilikaudellaOstetutOmatOsakkeet: number
  tilikaudellaOstetutOmatOsakkeetNakyvissa: boolean

  /** Tilikaudella luovutetut omat osakkeet: Ohjataan yllä olevasta "Yhtiö on hankkinut tai luovuttanut tilikaudella omia osakkeitaan" - täpästä */
  tilikaudellaMyydytOmatOsakkeet: number
  tilikaudellaMyydytOmatOsakkeetNakyvissa: boolean

  /** Edellisten tilikausien voitto(tappio) tilikauden lopussa: = Edellisten tilikausien voitto(tappio) tilikauden alussa + Osingonjako tilikaudella + Tilikaudella hankitut omat osakkeet - Tilikaudella luovutetut omat osakkeet */
  edellistenTilikausienVoittoTaiTappioTilikaudenLopussa: number
  edellistenTilikausienVoittoTaiTappioTilikaudenLopussaNakyvissa: boolean

  /** Tilikauden voitto(tappio): Tuloksen(tai taseen) mukainen kyseisein tilikauden tilikauden voitto */
  tilikaudenVoittoTaiTappio: number
  tilikaudenVoittoTaiTappioNakyvissa: boolean

  /** Vapaa oma pääoma: Sijoitetun vapaan oman pääoman rahasto tilikauden lopussa + Edellisten tilikausien voitto(tappio) tilikauden lopussa + Tilikauden voitto(tappio) */
  vapaaOmaPaaoma: number
  vapaaOmaPaaomaNakyvissa: boolean

  /** Oma pääoma yhteensä: Vapaa oma pääoma + Sidottu oma pääoma */
  omaPaaomaYhteensa: number
  omaPaaomaYhteensaNakyvissa: boolean

  /** Oma pääoma + pääomalainat: Oma pääoma yhteensä + "Yhtiöllä on pääomalainaa" - täpän luku */
  omaPaaomaPlusPaaomalainat: number

  /** Jos Oma pääoma yhteensä ei täsmää taseen OMA PÄÄOMA -summarivin kanssa, nostetaan punainen teksti: "Oma pääoma ei täsmää taseen kanssa!" */
  omaPaaomaEiTasmaa: boolean

  jakokelpoinenOmaPaaomaYhteensa: number

}


export interface TilinpaatoksenTilintarkastustiedonLiite {
  avain: string
  nimi: string
  tyyppi: /** 'png' | 'jpg' | */ 'pdf'
  poistettu: boolean
}

export interface TilinpaatoksenTilintarkastustiedot extends BaseEntity {
  teksti: string
  // merkinta: TilinpaatoksenTilintarkastustiedonLiite
  kertomus: TilinpaatoksenTilintarkastustiedonLiite
}

export interface TilinpaatoksenTilintarkastuksenLiitetiedostonLatauspyynto {
  asiakasAvain: string
  tilikausiAvain: string
  liite: TilinpaatoksenTilintarkastustiedonLiite
}

export interface TilinpaatoksenTilintarkastuksenLiitetiedostonLatausvastaus extends ErrorResponse {
  base64File: string
}

// export interface MaksutavatJoissaOnKirjauksiaRequest {
//   asiakasAvain: string
//   alkaa: number
//   loppuu: number
// }

// export interface MaksutavatJoissaOnKirjauksiaResponse {
//   m: { [maksutavanAvain: string]: 1 }
// }


export interface ExportPresignedTilinpaatosToLemonaidRequest {
  asiakasAvain: string
  tilikausiAvain: string
  base64File: string
}

export interface ExportPresignedTilinpaatosToLemonaidResponse extends ErrorResponse<'unknown-err' | 'unauthorized' | 'no-data' | 'no-asiakas'> {

}

export interface ExportPresignedPoytakirjaToLemonaidRequest {
  asiakasAvain: string
  tilikausiAvain: string
  dateOfConfirmation: NumberTimestamp
  dividendsPayableDate: NumberDate
  dividendsDistributed: boolean
  voittoPaatosText: string
  base64File: string
}

export interface ExportPresignedPoytakirjaToLemonaidResponse extends ErrorResponse<'unknown-err' | 'unauthorized' | 'no-data' | 'no-asiakas'> {

}

export interface ResetPoytakirjaInLemonaidRequest {
  asiakasAvain: string
  tilikausiAvain: string
}

export interface ResetPoytakirjaInLemonaidResponse extends ErrorResponse<'unknown-err' | 'unauthorized' | 'no-data' | 'no-asiakas'> {

}

export interface DownloadPreviouslyGeneratedTilinpaatosPdfRequest {
  asiakasAvain: string
  tilikausiAvain: string
  /** If not given, downloads the latest version */
  versioAvain?: string
}

export interface DownloadPreviouslyGeneratedTilinpaatosPdfResponse extends ErrorResponse<'unknown-err' | 'unauthorized' | 'no-data' | 'no-asiakas' | 'view-not-allowed'> {
  base64Pdf?: string
  nimi?: string
}

export interface DownloadUploadedPoytakirjaPdfRequest {
  asiakasAvain: string
  tilikausiAvain: string
  /** If not given, downloads the latest version */
}

export interface DownloadUploadedPoytakirjaPdfResponse extends ErrorResponse<'unknown-err' | 'unauthorized' | 'no-data' | 'no-asiakas' | 'view-not-allowed'> {
  base64Pdf?: string
  nimi?: string
}


export interface CustomTilinpaatosUploadHistory extends BaseHistoryEntity {
  asiakasAvain: string
  tilikausiAvain: string
}

export interface ResetPoytakirjaHistory extends BaseHistoryEntity {
  asiakasAvain: string
  tilikausiAvain: string
}

export interface TilintarkastuskertomusUploadedTyojonoData {
  luotu: Timestamp
  asiakasAvain: string
  tilikausiAvain: string
}

export interface LiitetiedotTallennettuTyojonoData {
  luotu: Timestamp
  asiakasAvain: string
  tilikausiAvain: string
}

export interface TilikaudenAvainluvut {
  /** Liikevaihto */
  l: number
  /** Taseen loppusumma */
  t: number
}
